import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { vhostV1 } from '../../funcoes';
import { useEffect } from 'react';
import api from '../../../services/api';



function gerarNotaFiscal(venda) {

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

   console.log(venda);
   

    
    
   const nota = {
    nomeFantasia: "NOME DE FANTASIA",
    razaoSocial: "NOME DA RAZÃO SICIAL COM. DE INFORMÁTICA LTDA-ME",
    endereco: `${venda.ENDERECO}, ${venda.NREND1} \n${venda.BAIRRO} - ${venda.CEP} - ${venda.CIDADE}/${venda.ESTADO}`,
    telefones: "(21) 1111-2222 / 3344-5566",
    cnpj: venda.CNPJ,
    ie: "11.222.333",
    cliente: venda.RAZAO,
    data: venda.DATA,
    numeroNota: venda.DOCUMENTO,
    itens: venda.ITENS.map(item => ({
        codigo: item.COD_PRODUTO,
        descricao: item.DESCRICAO,
        qtd: item.QTD,
        unitario: item.VENDA,
        total: item.TOTAL
    })),
    totalNota: venda.VALOR,
    formaPgto: venda.FORMA_PAGAMENTO,
    pagamento: {
        data: venda.DATA, 
        valor: venda.VALOR,
        tipo: "DINHEIRO" 
    },
    vendedor: venda.COD_VENDEDOR, 
   
};
    
      
      const docDefinition = {
        pageSize: { width: 58 * 2.83465, height: 'auto' }, 
        pageMargins: [5, 5, 5, 10], // Margens mínimas
        content: [
          { text: nota.nomeFantasia, style: 'header', margin: [0, 0, 0, 2] },
          { text: nota.razaoSocial, style: 'subheader', margin: [0, 0, 0, 2] },
          { text: nota.endereco, style: 'subheader', margin: [0, 0, 0, 2] },
          { text: nota.telefones, style: 'subheader', margin: [0, 0, 0, 2] },
          { text: `CNPJ: ${nota.cnpj}    IE: ${nota.ie}`, margin: [0, 5], fontSize: 8 },
          { text: `CLIENTE: ${nota.cliente}`, margin: [0, 3], fontSize: 8 },
          { text: `Data: ${nota.data}    Nota Nº: ${nota.numeroNota}`, margin: [0, 3], fontSize: 8 },
          {
            table: {
              headerRows: 1,
              widths: ['*', 'auto'],
              body: [
                [{ text: 'DESCRIÇÃO', bold: true, fontSize: 8 }, { text: 'VALOR', bold: true, fontSize: 8 }],
                ...nota.itens.map(item => [
                  { text: item.descricao, fontSize: 8 },
                  { text: `R$ ${item.total.toFixed(2)}`, fontSize: 8, alignment: 'right' }
                ])
              ]
            },
            layout: 'noBorders',
            margin: [0, 5]
          },
          { text: `Total da Nota: R$ ${nota.totalNota.toFixed(2)}`, margin: [0, 5], fontSize: 8 },
          
          { text: `Forma de Pagamento: ${nota.formaPgto}`, margin: [0, 5], fontSize: 8 },
          { text: `Data Pgto: ${nota.pagamento.data}    Valor: R$ ${nota.pagamento.valor.toFixed(2)}    Tipo: ${nota.pagamento.tipo}`, margin: [0, 2], fontSize: 8 },
          { text: `VENDEDOR(A): ${nota.vendedor}`, margin: [0, 5], fontSize: 8 },
          { text: 'Recebi a(s) mercadoria(s) acima descrita(s), concordando plenamente com os prazos e condições de garantia.', margin: [0, 5], fontSize: 8 },
          { text: '____________________________________', margin: [0, 10], fontSize: 8 },
          { text: 'ASSINATURA DO CLIENTE', alignment: 'center', margin: [0, 5], fontSize: 8 },
          { text: '* OBRIGADO E VOLTE SEMPRE *', alignment: 'center', margin: [0, 10], bold: true, fontSize: 8 },
        ],
        styles: {
          header: { fontSize: 10, bold: true, alignment: 'center' },
          subheader: { fontSize: 8, alignment: 'center' }
        },
        defaultStyle: {
          fontSize: 8
        }
      };
    
      // Gera o PDF
      pdfMake.createPdf(docDefinition).open();
    }

  export default gerarNotaFiscal;
  