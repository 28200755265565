import React, { useState, useEffect } from 'react';
import { Link,Navigate } from 'react-router-dom';
import Navbar from '../navbar/navbar';
import './grupo.css'
import { api } from '../../services/api';
import { vhostV1 } from '../funcoes';
import SweetAlert from 'react-bootstrap-sweetalert';
import customizeScrollbar from "customsb";
import FooterBar from '../footer/footerBar';



function Grupo() {


     const [texto,setTexto] = useState('');
     const [busca,setBusca] = useState("");
     const [grupos,setGrupos] = useState([]);
     const [load,setLoad] = useState(false);
     const [nPag,setnPag] = useState(1);
     const [totalPaginas,setTotalPaginas] = useState(0); 
     const [tpBusca,setTpBusca] = useState(0);
    
     const [produtos,setProdutos] = useState([]);
     const [sResult,setSresult] = useState(0);
     const [erroValidacao, setErrovalidacao] = useState(false);
     const [msgErro, setmsgErro] = useState('') ;
     const [dCadastro,setDcadastro] = useState(new Date);
     const [dAlteracao,setDalteracao] = useState(new Date);
     const [dultCompra,setDultcompra] = useState(new Date);
     const [dultVenda,setDultvenda] = useState(new Date);

     const [usaDcadastro,setUsaDcadastro] = useState(false);
     const [usaDAlteracao,setusaDalteracao] = useState(false);
     const [usaDultCompra,setusaDultcompra] = useState(false);
     const [usaDultVenda,setusaDultvenda] = useState(false);


     const [cGrupo,setCgrupo] = useState(0);
     const [cSubGrupo,setCsubGrupo] = useState(0);
     const [cLinha,setClinha] = useState(0);


     const [diferenteZero,setDiferenteZero] = useState(false);
     const [estoqueNegativo,setEstoqueNegativo] = useState(false);
     const [vendaZerada,setVendaZerada] = useState(false);
     const [prodInativo,setProdInativo] = useState(false);

     const [navegar, setNavegar] = useState(false);
     const [confirmacao,setConfirmacao] = useState(false);
     const [confirmacaoId,setConfirmacaoID] = useState('');

      function deleteUser(id){
        api.post(vhostV1('webcash/grupo/excluir'), {
            loja: localStorage.getItem('loja'),
            empresa: 0,
            cod_grupo: id
          },config)
          .then(function (response) {
            setConfirmacao(false);
            setNavegar(true);
                    
          })
          .catch(function (error) {
            console.log(error);
            setConfirmacao(false);
            
          });
        
      
      }

      useEffect(function(){
        setLoad(true)
        //console.log('numero de Paniga '+nPag);
        const sJson = {}
        sJson.loja=localStorage.getItem('loja'); 
        sJson.empresa=0;
        sJson.grupo=cGrupo;
        sJson.subgrupo=cSubGrupo;
        sJson.linha=cLinha;
        if(usaDcadastro === true)
        { sJson.data_cadastro=dCadastro}
        if(usaDAlteracao === true)
        { sJson.data_alteracao=dAlteracao}
        if(usaDultCompra === true)
        { sJson.data_compra=dultCompra}
        if(usaDultVenda === true)
        { sJson.data_venda=dultVenda}
        sJson.estoque_diferente=diferenteZero
        sJson.estoque_negativo=estoqueNegativo;
        sJson.preco_zerado=vendaZerada;
        sJson.inativo=false;
        sJson.pagina= nPag;
        sJson.regPag=50;
        sJson.busca= busca; 
        sJson.tipoBusca=tpBusca;                   
        
          api.post(vhostV1('webcash/produto/listar'),sJson,config)
            .then(function (response) {
              console.log(response.data.dados);           
              setProdutos(response.data.dados); 
              setSresult(response.data.result)
              setTotalPaginas(response.data.qtdpaginas);
              setLoad(false);
              
                                          
                       
            })
            .catch(function (error) {
              setLoad(false);
              console.log(error);
    
                      
            });
      
            
          },[busca,nPag])


          // Função para verificar se um grupo pode ser excluído
function podeExcluirGrupo(id) {
  // Verificar se há produtos associados ao grupo
  const produtosAssociados = produtos.some(produto => produto.COD_GRUPO ===Number(id));
  console.log(produtosAssociados);
  
  
  if (produtosAssociados) {
    console.log(`Não é possível excluir o grupo com código ${id} porque existem produtos associados a ele.`);
    return false;
  }

  return true;
}

    function confirmadeleteUser(id){
      if(podeExcluirGrupo(id) === false){
        
        setmsgErro(`Não é possível excluir o grupo com código ${id} porque existem produtos associados a ele.`);
        setErrovalidacao(true)
      }else{
      setConfirmacaoID(id);
      setConfirmacao(true);
      setBusca('');
      
    }}

     // Aplicando a personalização com opções diferentes
// Abaixo é somente um exemplo de personalização, adapte para o seu projeto
useEffect(() => {
  // O uso do useEffect garante que a função seja chamada apenas no lado do cliente
  customizeScrollbar({
    scrollbarWidth: "10px",
    trackBackground: "#333",
    thumbBackground:
      "linear-gradient(178.8deg, #ffa066 47.41%, #ff9666 65.18%, #ff783a 90.84%)",
    thumbBorderRadius: "10px",
  });
}, []);


  let config = {
    headers: {
      'Authorization': localStorage.getItem("token")
    }
  }

  //buscar clientes
  useEffect(function(){
    
      api.post(vhostV1('webcash/grupo/listar'), {
          loja: localStorage.getItem('loja'),
          empresa: 0,
          busca: busca
        },config)
        .then(function (response) {
          setGrupos(response.data.dados);
          console.log(response.data.dados); 
                                      
                   
        })
        .catch(function (error) {
         
          console.log(error);

                  
        });
  
        
    },[busca])


    return <div>
        <Navbar/>
        <div className="container-fluid titulo offset-lg-1 col-lg-10">
        <h1>Cadastro de Grupos</h1>
        <hr />

        <div className="row">
          <div className="col-4">
            <Link to='/app/novoGrupo/-1' className="btn btn-primary btn-cli" type="button"><i className="bi bi-plus-circle-fill"></i> Grupo</Link>
            

          </div>

          <div className="col-8">
            <div className="input-group mb-3">
              <input onChange={(e) => setTexto(e.target.value)} type="text" className="form-control" placeholder="Digite sua pesquisa" aria-describedby="button-addon2" />
              <button onClick={(e) => setBusca(texto)} className="btn btn-primary" type="button" id="button-addon2">Pesquisar</button>
            </div>
          </div>
        </div>   

         <table id='table-grupos' className="table table-hover table-striped">
            <thead>
            <tr>
                <th scope='col'>Código</th>
                <th scope="col">Descricao</th>
                <th scope="col">Mobile</th>
                <th scope="col">Ações</th>
                </tr>
            </thead>
            <tbody className="table-group-divider">
              {  
                 grupos.map((grupo)=> {
                  return <tr key={grupo.cod_grupo}>
                      <td>{grupo.cod_grupo}</td>
                      <td>{grupo.descricao}</td>
                      <td>{grupo.carga === '1' ?'SIM':'NÃO'}</td>
                      <td>
                      <Link to ={'/app/novoGrupo/' +grupo.cod_grupo}><i className="bi bi-pen-fill"></i></Link>
                      <Link to ="#" onClick={() => confirmadeleteUser(grupo.cod_grupo)}><i className="bi bi-trash-fill red"></i></Link>
                      </td>
                  </tr>
              } )}  
            </tbody>
          </table>

          {
           erroValidacao ? 
           <SweetAlert
              danger
              confirmBtnText="OK"
              confirmBtnBsStyle="danger"
              title="Erro"
              onConfirm={() => setErrovalidacao(false)}                            
            >
              {msgErro}
            </SweetAlert>:null
           
          }

          {
           confirmacao ? 
           <SweetAlert
              warning
              showCancel
              showCloseButton
              confirmBtnText="Sim"
              confirmBtnBsStyle="danger"
              cancelBtnText="Não"
              cancelBtnBsStyle='ligth'
              title="Atenção"
              onConfirm={() =>deleteUser(confirmacaoId)}
              onCancel={() => setConfirmacao(false)}
              focusCancelBtn
              reverseButtons
            >
              Deseja excluir Este Grupo!
            </SweetAlert>:null
           
          }

          {navegar ? <Navigate to='/app/grupo' /> && window.location.reload()  : null}
     
      </div>  
      <FooterBar/>   
    </div>
}


export default Grupo;