import React from "react";
import Navbar from "../navbar/navbar";
import { Link } from "react-router-dom";
import { useState,useEffect } from "react";
import api from "../../services/api";
import { vhostV1 } from "../funcoes";
import './empresa.css';
import customizeScrollbar from "customsb";
import FooterBar from "../footer/footerBar";


function Empresa(){

     const [texto,setTexto] = useState('');
     const [busca,setBusca] = useState("");
     const [empresas,setEmpresas] = useState([]);
     const [sResult,setSresult] = useState('N');

     let config = {
        headers: {
          'Authorization': localStorage.getItem("token")
        }
      }


     useEffect(function(){
    
        api.post(vhostV1('webcash/empresa/lista'), {
            loja: localStorage.getItem('loja')
            
          },config)
          .then(function (response) {           
            setEmpresas(response.data.dados); 
            setSresult(response.data.result)
            
                                        
                     
          })
          .catch(function (error) {
           
            console.log(error);
  
                    
          });
    
          
        },[busca])

         // Aplicando a personalização com opções diferentes
// Abaixo é somente um exemplo de personalização, adapte para o seu projeto
useEffect(() => {
  // O uso do useEffect garante que a função seja chamada apenas no lado do cliente
  customizeScrollbar({
    scrollbarWidth: "10px",
    trackBackground: "#333",
    thumbBackground:
      "linear-gradient(178.8deg, #ffa066 47.41%, #ff9666 65.18%, #ff783a 90.84%)",
    thumbBorderRadius: "10px",
  });
}, []);




    return  <div>
    <Navbar/>
    <div className="container-fluid titulo offset-lg-1 col-lg-10">
        <h1>Empresas</h1>
        <hr />
        <div className="row">
         {/* <div className="col-4">
            <Link to='/app/novaempresa/-1' className="btn btn-primary btn-cli" type="button"><i className="bi bi-plus-circle-fill"></i> Produto</Link>
      </div>*/}
                    
        </div>   
        { sResult === 1?
         <table  id="table-empresas" className="table table-hover table-striped">
            <thead>
            <tr>
                <th scope="col">Codigo</th>
                <th scope="col">Empresa</th>
                <th scope="col">Cnpj</th>
                <th scope="col">Regime</th>
                
                </tr>
            </thead>
            <tbody className="table-group-divider">
              {  
                 empresas.map((empresa)=> {
                  return <tr key={empresa.EMPRESA}>
                      <td>{empresa.EMPRESA}</td>          
                      <td>{empresa.RAZAO_SOCIAL}</td>          
                      <td>{empresa.CGC}</td>
                      {empresa.REGIME === 2?<td>Regime Normal</td>:<td>Simples Nacional</td>}
                      
                  </tr>
              } )}  
            </tbody>
          </table>

           :null} 
          
     
      </div>     

      <FooterBar/>  
    
   </div>



}



export default Empresa;