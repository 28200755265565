import customizeScrollbar from "customsb";
import React, { useEffect, useState } from "react";
import Navbar  from "../navbar/navbar";
import { api } from '../../services/api';
import { vhostV1 } from '../funcoes';
import './cadastro.css'
import { Link, Navigate } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import BuscaVazaia from "../components/buscavazia/buscaVazia";

function CadastroMotoqueiro() {
 

    const [erroValidacao, setErrovalidacao] = useState(false);
    const [navegar, setNavegar] = useState(false);
    const [confirmacao, setConfirmacao] = useState(false);
    const [msgErro, setmsgErro] = useState('');
    const [sResult, setSresult] = useState(1);
    const [cadastros, setCadastros] = useState([{COD_CADASTRO:1, RAZAO: 'Motoqueiro', CPF: '07012345678', ENDERECO: 'COHAB'}]);
    const [texto, setTexto] = useState('');
    const [busca, setBusca] = useState('');



    let config = {
        headers: {
          'Authorization': localStorage.getItem("token")
        }
      }

    // useEffect(() => {
    //     // O uso do useEffect garante que a função seja chamada apenas no lado do cliente
    //     customizeScrollbar({
    //       scrollbarWidth: "10px",
    //       trackBackground: "#333",
    //       thumbBackground:
    //         "linear-gradient(178.8deg, #ffa066 47.41%, #ff9666 65.18%, #ff783a 90.84%)",
    //       thumbBorderRadius: "10px",
    //     });
    //   }, []);

    return <div>
    <Navbar/>
    <div className="container-fluid titulo offset-lg-1 col-lg-10">
      <h1>Cadastro Motoqueiro</h1>
    
    <hr />

    <div className="row">
      <div className="col-4">
        <Link id='botao-cadastro' to={'/app/novoCadastroMotoqueiro/-1/1'} className="btn btn-primary btn-cli" type="button"><i className="bi bi-plus-circle-fill"></i> Cadastrar</Link>
        <button id='botao-cadastro' className="btn btn-danger btn-cli" type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i className="bi bi-printer-fill"></i> Relatório</button>
      </div>

      


      <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">Relátorio de Motoqueiro</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="contnt-modal">
               <h5>ordenar por </h5> 
              <div className="form-check">
                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  Código
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input" type="radio" defaultChecked={false} name="flexRadioDefault" id="flexRadioDefault2" checked/>
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                  Alfabética
                </label>
              </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
             
              

            </div>
          </div>
        </div>
      </div>

      <div className="col-8">
        <div className="input-group mb-3">
          <input onChange={(e) => setTexto(e.target.value)} type="text" className="form-control" placeholder="Digite sua pesquisa" aria-describedby="button-addon2" />
          <button onClick={(e) => setBusca(texto)} className="btn btn-primary" type="button" id="button-addon2"><i className="bi bi-search"></i> Pesquisar</button>
        </div>
      </div>
    </div>   
    { sResult === 1?
    <>
     <table id='table-cliente' className="table table-hover table-striped">
        <thead>
        <tr>
            <th scope="col">Codigo</th>
            <th scope="col">Nome</th>
            <th scope="col">Cpf</th>
            <th scope="col">Endereço</th>
            <th scope="col">Ações</th>
            </tr>
        </thead>
        <tbody className="table-group-divider">
          {  
             cadastros.map((cadastro)=> {
              return <tr key={cadastro.COD_CADASTRO}>
                  <td>{cadastro.COD_CADASTRO}</td>          
                  <td>{cadastro.RAZAO}</td>
                  <td>{cadastro.CPF}</td> 
                  <td>{cadastro.ENDERECO}</td>                     
                  <td>
                  <Link to ={'/app/novoCadastroMotoqueiro/'+cadastro.COD_CADASTRO+'/0'}><i className="bi bi-eye-fill green"></i></Link>  
                  <Link to ={'/app/novoCadastroMotoqueiro/'+cadastro.COD_CADASTRO+'/2'}><i className="bi bi-pen-fill"></i></Link>
                  <Link to ="#"><i className="bi bi-trash-fill red"></i></Link>
                  </td>
              </tr>
          } )}  
        </tbody>
      </table>
      <div className="row"></div>
      <nav aria-label="Navegação de página exemplo">
        <ul class="pagination">
          <li class="page-item"><a class="page-link" href="#">Anterior</a></li>
          <li class="page-item"><a class="page-link" href="#">1</a></li>
          <li class="page-item"><a class="page-link" href="#">2</a></li>
          <li class="page-item"><a class="page-link" href="#">3</a></li>
          <li class="page-item"><a class="page-link" href="#">Próximo</a></li>
        </ul>
      </nav>
      </>
     :<BuscaVazaia/>} 

{/* {
       erroValidacao ? 
       <SweetAlert
          danger
          confirmBtnText="OK"
          confirmBtnBsStyle="danger"
          title="Erro"
          onConfirm={() => setErrovalidacao(false)}                            
        >
          {msgErro}
        </SweetAlert>:null
       
      } */}

      {/* {
       confirmacao ? 
       <SweetAlert
          warning
          showCancel
          showCloseButton
          confirmBtnText="Sim"
          confirmBtnBsStyle="danger"
          cancelBtnText="Não"
          cancelBtnBsStyle='ligth'
          title="Atenção"
          onConfirm={() =>deleteUser(confirmacaoId)}
          onCancel={() => setConfirmacao(false)}
          focusCancelBtn
          reverseButtons
        >
          Deseja excluir o cliente!
        </SweetAlert>:null
       
      } */}

{navegar ? <Navigate to='/app/cadastro/c'/> && window.location.reload() : null}
 
  </div>      
</div>
}

export default CadastroMotoqueiro;
