import { useEffect, useState } from 'react';
import Navbar from '../navbar/navbar'
import { api } from '../../services/api';
import { Link } from 'react-router-dom';
import { vhostV1 } from '../funcoes';
import BuscaVazaia from '../components/buscavazia/buscaVazia';
import Loading from '../components/loading/Loading';
import SweetAlert from 'react-bootstrap-sweetalert';
import SRI_pagination from '../components/pagination/pagination';
import WidgetCompra from '../components/widget/widgetCompra';
import customizeScrollbar from "customsb";
import FooterBar from '../footer/footerBar';




function Compra(){

    const [sResult,setSresult] = useState(0);
    const [load,setLoad] = useState(false);
    const [confirmaEmissao,setConfirmaEmissao] = useState(false);
    const [msgErro,setmsgErro] = useState('');
    const [erroEmissao,setErroEmissao] = useState(false);
    const [nPag, setnPag] = useState(1);
    const [totalPaginas, setTotalPaginas] = useState(0);
    const [pedidos, setPedidos] = useState([]);
    const [tpRelatorio,setTipoRelatorio] = useState(0);
    const [tipo,setTipo] = useState('TODOS');
    const [dataInical,setDataInicial] = useState(new Date); 
    const [dataFinal,setDataFinal] = useState(new Date);
    

    function handlePageChange(value){
      if(value === "&laquo;" || value === '... '){
        setnPag(1);
      }else if (value === "&lsaquo;" ){
        if (nPag !== 1){
          setnPag(-1);
        }
      }else if(value === "&rsaquo;"){
        setnPag(+1);        
      }else if(value === "&raquo;" || value === ' ...' ){
        setnPag(totalPaginas);        
      }else{
        setnPag(value);
      }    
     
     }

     let config = {
      headers: {
        'Authorization': localStorage.getItem("token")
      }
    }

     useEffect(function(){
      setLoad(true)

      const sJson = {}
      sJson.loja=localStorage.getItem('loja');
      sJson.empresa=0;
      sJson.pagina=nPag;
      sJson.regPag=10;
      sJson.busca='';
      sJson.status='T';

      api.post(vhostV1('webcash/compra/listaCompra'),sJson,config)
      .then(function(response){
        console.log(response.data);
        setPedidos(response.data.dados);
        setTotalPaginas(response.data.qtdpaginas);
        setSresult(response.data.result);
        setLoad(false);
      })
      .catch(function(error){
        console.log(error);
        setLoad(false);
      })
    


     },[nPag])

          // Aplicando a personalização com opções diferentes
// Abaixo é somente um exemplo de personalização, adapte para o seu projeto
useEffect(() => {
  // O uso do useEffect garante que a função seja chamada apenas no lado do cliente
  customizeScrollbar({
    scrollbarWidth: "10px",
    trackBackground: "#333",
    thumbBackground:
      "linear-gradient(178.8deg, #ffa066 47.41%, #ff9666 65.18%, #ff783a 90.84%)",
    thumbBorderRadius: "10px",
  });
}, []);

     function filtraCompra(){
      setLoad(true);

      api.post(vhostV1('webcash/compra/listaCompra'),{
        loja: localStorage.getItem('loja'),
        empresa:0,
        pagina:nPag,
        regPag:10,
        busca:'',
        status:''
      },config)
      .then(function(response){
        setPedidos(response.data.dados);
        setTotalPaginas(response.data.qtdpaginas);
        setSresult(response.data.result);
        setLoad(false);
      }).catch(function(error){
        console.log(error);
        setLoad(false);
      })


     }




  return<div>

  <Navbar/>
  <div className="container-fluid titulo offset-lg-1 col-lg-10">

  <div className="d-flex justify-content-between"> 
  <h2 className="d-inline" >Compras</h2>
  
     
          <div>
          
          <Link type="button"  className="btn btn-primary btn-cli"  to="/app/estoque/novacompra"><i className="bi bi-plus-circle-fill"></i> Incluir</Link>
          <button type="button" className="btn btn-secondary btn-cli" data-bs-toggle="modal" data-bs-target="#moda-filtro"><i className="bi bi-funnel-fill"></i>Filtros</button>
          {/*<button type="button" className="btn btn-danger btn-cli"  data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i className="bi bi-printer-fill"></i> Relatório</button>
          <SalesReport/>*/}
          </div>

          
          
      </div> 
   
  <hr/>
  

  <div className="row">
          <WidgetCompra/>
  </div>

  
  <div className="modal fade" id="moda-filtro" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="staticBackdropLabel">Filtros</h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <div className="contnt-modal">
                    <div className="row">
                      
                    <div >
                        <select className='form-control' name="status" id="status" onChange={(e)=> setTipoRelatorio(e.target.value)}>
                            <option value={0}>Lista</option>
                            <option value={1}>Agrupado por Finalizadora</option>
                            <option value={2}>Agrupado por Cupom</option>
                        </select>
                    </div>                       
                    </div>      
                    <hr />                      

                   <div className="row">
                    <div>
                     <h5>Tipo  </h5>   
                     <div>
                     <div className="form-control d-inline me-3">
                        <select name="status" id="status" onChange={(e)=> setTipo(e.target.value)}>
                            <option value="TODOS">Todos</option>
                            <option value="RECIBO">Autorizado</option>
                            <option value="CANCELADO">Cancelado</option>
                        </select>
                    </div>
                    
                   </div>
                   </div>

                   
                          
                     </div>
                     <br />

                     <div className="row">
                    
                    </div>


                    <div className="row">
                     <h5>Período</h5>   
                     <div>
                     <div className='d-flex col-12'>           
                        <input type="date" onChange={(e)=> setDataInicial(e.target.value+"T10:10:00.000Z")} className="form-control" />
                         
                        <input type="date" onChange={(e)=> setDataFinal(e.target.value+"T10:10:00.000Z")} className="form-control" />
                    </div>
                     </div>
                     </div>

                  </div>
                </div>
                <div className="modal-footer">
                  <div>                  
                  { tpRelatorio===0?
                  <button type="button" onClick={filtraCompra} className="btn btn-secondary btn-cli" data-bs-dismiss="modal">Ativar</button>
                  :null}
                  
                  <button type="button"  className="btn btn-success btn-cli" data-bs-dismiss="modal">Imprimir</button>
                  </div>

                  

                </div>
              </div>
            </div>
          </div>


 { load === true?<Loading/>:null}   
 { sResult === 1?
  <table id='form-label'  className="table table-hover table-striped">
      <thead>
      <tr>
          <th scope="col">PEDIDO</th>
          <th scope="col">CLIENTE</th>
          <th scope="col">Nº NOTA</th>
          <th scope="col">DATA</th>
          <th scope="col">CANCELADO</th>
          <th scope="col">VALOR</th>
          <th scope="col">AÇÃO</th>
          </tr>
      </thead>
      <tbody className="table-group-divider">
      {  
      pedidos.map((pedido) => {
          return <tr key={pedido.COD_COMPRA}>
                 <td>{("0000" + pedido.COD_COMPRA).slice(-6)}</td>
                 <td>{pedido.RAZAO}</td>
                 <td>{pedido.DOCUMENTO}</td>
                 <td>{pedido.DATA}</td>
                 <td>{pedido.CANCELADO}</td>
                 <td> R$ {Number(pedido.VALOR_TOTAL_NOTA).toFixed(2)}</td>
                
                 
                 
                 

                 <td>
                   <div className="dropdown" >
                      <button className="btn btn-outline-secondary dropdown-toggle" id='botao-opcoes' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Opções
                      </button>
                      <ul className="dropdown-menu">
                      {pedido.STATUS === "T"?
                        <>
                        <li><button className="dropdown-item"  value={pedido.COD_COMPRA}><i className="bi bi-printer-fill"></i>Imprimir Nota</button></li>
                        <li><button className="dropdown-item"  value={pedido.COD_COMPRA}><i className="bi bi-x-circle-fill"></i>Cancelar Nota</button></li>
                        </>:
                        <><li><button  value={pedido.COD_COMPRA} className="dropdown-item"><i className="bi bi-pen-fill"></i>Editar</button></li>
                          {pedido.responsavel === "0"?null:<li><button  value={pedido.COD_COMPRA} className="dropdown-item"><i className="bi bi-trash-fill"></i>Deletar</button></li>}
                          </>}
                        
                          <li><a href={'/app/itenspedido/'+pedido.cod_orcamento} className="dropdown-item"><i className="bi bi-search"></i>Visualizar</a></li>

                      </ul>
                   </div>
                </td>
                 {/*
                 <td><Link to={'/app/itenspedido/'+pedido.cod_orcamento}><i className="bi bi-search"></i></Link></td>
                 */}

                 </tr>
        } )}  
      </tbody>
    </table>          
    :<BuscaVazaia/>} 


            <div className="row mt-5">
            <SRI_pagination totalPage={totalPaginas} 
                            page={nPag} 
                            limit={10} 
                            siblings={1}
                            onPageChange={handlePageChange}/>
            </div>
 

   {
     confirmaEmissao ? 
     <SweetAlert
        success                         
        confirmBtnText="OK"
        confirmBtnBsStyle="success"
        title="Parabéns"
        onConfirm={() => setConfirmaEmissao(false)}                            
      >
        Nota Emitida com sucesso!
      </SweetAlert>:null
     
    }

    {
     erroEmissao ? 
     <SweetAlert
        danger
        confirmBtnText="OK"
        confirmBtnBsStyle="danger"
        title="Erro"
        onConfirm={() => setErroEmissao(false)}                            
      >
        {msgErro}
      </SweetAlert>:null
     
    }

  

</div>   
<FooterBar/>   
</div>
  
}

export default Compra
