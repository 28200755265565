import React, { useState,useEffect } from 'react'
import Navbar from '../navbar/navbar'
import { NumericFormat } from 'react-number-format'
import './compra.css';
import BuscaVazaia from '../components/buscavazia/buscaVazia';
import api from '../../services/api';
import { vhostV1 } from '../funcoes';
import ModalItens from './modalItens';
import ModalAssociacao from './modalAssociacao';
import ModalProduto from '../components/modalProduto/ModalProduto';
import { Link} from 'react-router-dom'
import FooterBar from '../footer/footerBar';


function NovaCompra(){

  var data = new Date();
  var dia = String(data.getDate()).padStart(2, '0');
  var mes = String(data.getMonth() + 1).padStart(2, '0');
  var ano = data.getFullYear();
  const dataAtual = dia + '/' + mes + '/' + ano;

    let config = {
        headers: {
          'Authorization': localStorage.getItem("token")
        }
      }

    const [isModalItensOpen,setIsModalItensOpen] = useState(false);
    const [totalPedido,setTotalPedido] = useState(0);
    const [file, setFile] = useState(null);
    const [gridProduto,setGridProduto] = useState(false);
    const [sResult,setSresult] = useState(0);
    const [produtosBusca,setProdutosBusca] = useState([])
    const [produtos,setProdutos] = useState([])
    const [pesquisaProduto,setPesquisaProduto] = useState('');
    const [cnpjEmitente,setCnpjEmitente] = useState('');
    const [razaoEmitente,setRazaoEmitente] = useState('');
    const [isModalAssociacaoOpen,setIsModalAssociacaoOpen] = useState(false);
    const [prodAssociar,setProdAssociar] = useState('');
    const [isProdutoOpen,setIsProdutoOpen] = useState(false);
    const [diferenteZero,setDiferenteZero] = useState(false);
    const [estoqueNegativo,setEstoqueNegativo] = useState(false);
    const [vendaZerada,setVendaZerada] = useState(false);
    const [prodInativo,setProdInativo] = useState(false);
    const [codProduto,setcodProduto] = useState('');
    const [descProduto,setDescProduto] = useState('');
    const [codCompra,setCodCompra] = useState(0);
    const [chave,setChave] = useState('');
    const [itens, setItens] = useState([]);
    const [qtd, setQtd] = useState(0); 
    const [descricao, setDescricao] = useState('');
    const [vendaDefault, setVendaDefault]= useState(0);
    const [produtosFiltro, setProdutosFiltro] = useState([]);

    const [cGrupo,setCgrupo] = useState(0);
     const [cSubGrupo,setCsubGrupo] = useState(0);
     const [cLinha,setClinha] = useState(0);

     const [usaDcadastro,setUsaDcadastro] = useState(false);
     const [usaDAlteracao,setusaDalteracao] = useState(false);
     const [usaDultCompra,setusaDultcompra] = useState(false);
     const [usaDultVenda,setusaDultvenda] = useState(false);

     const [dCadastro,setDcadastro] = useState(new Date);
     const [dAlteracao,setDalteracao] = useState(new Date);
     const [dultCompra,setDultcompra] = useState(new Date);
     const [dultVenda,setDultvenda] = useState(new Date);

     

    
    

       function openModalProduto(){
          setIsProdutoOpen(true)
       }

       function closeModalProduto(){
          setIsProdutoOpen(false)
       }
    
    

  function openModalAssociacao(){      
    setIsModalAssociacaoOpen(true);
 }

 function closeModalAssociacao(){
  setIsModalAssociacaoOpen(false);
 }


 let qtdArray = produtos.map((p) => {
  return p.qCom
 })



 console.log(qtdArray);
 
 


    function openModalItens(){
      setIsModalItensOpen(true)
   }

   function closeModalItens(){
      setIsModalItensOpen(false)
   }


    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setChave(selectedFile.name)
        setFile(selectedFile);
        
    };

    const handleUpload = () => {
        if (file) {
        const reader = new FileReader();
        reader.onload = () => {
            const base64Data = reader.result.split(',')[1]; // Obtém a parte de dados do base64
            // Envie o base64Data para a sua API aqui
            enviaXml(base64Data);
                    
        };
        reader.readAsDataURL(file);
        }
    };


    async function enviaXml(sXml){
        
        let response = {};
        try {
          response = await api.post(vhostV1('webcash/lerXML'),{
            loja: localStorage.getItem('loja'),
            empresa: 0,
            xml:sXml
          }, config)

          setSresult(1);
         console.log(response.data);
          setCnpjEmitente(response.data.emitente.cnpj);
          // setTotalPedido(response.data.emitente.vNF);
          setRazaoEmitente(response.data.emitente.xNome);
          setProdutos(response.data.produtos);
          
          
        
          
         // setIsModalItensOpen(true)

        } catch (error) {
          console.log(error);          
        }
         
            
        //setItens(response.data.dados); 
        //setResultItens(response.data.result)     
      
      }



      function abreAssociacao(cod){
        setProdAssociar(cod);

        setIsModalAssociacaoOpen(true);
      }

      function abreLancaItem(cod){
        setcodProduto(cod);
        //setProdAssociar(cod) 
        setIsProdutoOpen(false)
       
      }  

      useEffect(function(){
     
        //console.log('numero de Paniga '+nPag);
        const sJson = {}
        sJson.loja=localStorage.getItem('loja'); 
        sJson.empresa=0;
        sJson.grupo=cGrupo;
        sJson.subgrupo=cSubGrupo;
        sJson.linha=cLinha;
        if(usaDcadastro === true)
        { sJson.data_cadastro=dCadastro}
        if(usaDAlteracao === true)
        { sJson.data_alteracao=dAlteracao}
        if(usaDultCompra === true)
        { sJson.data_compra=dultCompra}
        if(usaDultVenda === true)
        { sJson.data_venda=dultVenda}
        sJson.estoque_diferente=diferenteZero
        sJson.estoque_negativo=estoqueNegativo;
        sJson.preco_zerado=vendaZerada;
        sJson.inativo=false;
        sJson.pagina= 1;
        sJson.regPag=100000;
        sJson.busca= pesquisaProduto; 
        sJson.tipoBusca=0;                   
        
          api.post(vhostV1('webcash/produto/listar'),sJson,config)
            .then(function (response) {
              console.log(response.data.dados);           
              setProdutosFiltro(response.data.dados); 
              setSresult(response.data.result)
             
              
                                          
                       
            })
            .catch(function (error) {
             
              console.log(error);
    
                      
            });
      
            
          },[])

      function filtraProdutos(e){
        console.log(pesquisaProduto)
        e.preventDefault();
        const sJson = {}
        sJson.loja=localStorage.getItem('loja'); 
        sJson.empresa=0;
        sJson.grupo=0;
        sJson.subgrupo=0;
        sJson.linha=0;
        sJson.estoque_diferente=diferenteZero
        sJson.estoque_negativo=estoqueNegativo;
        sJson.preco_zerado=vendaZerada;
        sJson.inativo=prodInativo;
        sJson.pagina= 1;
        sJson.regPag=10;
        sJson.busca= pesquisaProduto;       
        
          api.post(vhostV1('webcash/produto/listar'),sJson,config)
            .then(function (response) {
                if(response && response.data && response.data.dados ){
                    console.log(response.data.dados)         
                    setProdutosBusca(response.data.dados);  
                }else{ 
                    setProdutosBusca([]);  
            }
                                         
            })
            .catch(function (error) {
              console.log(error);                  
            });
             
      }

      // function salvaConta(){
      //   api.post(vhostV1('webcash/compra/incluirCompra'),{
      //     empresa:0,
      //     loja:localStorage.getItem('loja'),
      //     COD_COMPRA: codCompra,
      //   },config).then(function(response){
      //     if(response){
      //       console.log(response);
      //     }
      //   }).catch(function (error){
      //     console.log(error);
      //   })
      // }

    

       // criando um pedido
        useEffect(function(){
            
          let response;       
       
          (async () => {
            try{
              response = await api.post(vhostV1('webcash/compra/incluirCompra'), {
                empresa:0,
                loja:0,
                codColaborador: localStorage.getItem("colaborador"),
                cod_compra:codCompra,
                },config);
                if(response && response.data && response.data.dados ){
                  
                  handleRetorno(response.data.dados.compra);
                }

              }catch(err){
               
                console.log(err.mensagem);
            }
          })()
           
          
      },[])


       function handleRetorno(id){
          setCodCompra(id)
          retornaCompra(id)
        }  

        
     

      function retornaCompra(id){
         
        api.get(vhostV1('webcash/compra/retornaCompra/'+id),config)
         .then(function (response) {
           if(response && response.data && response.data.dados){
            console.log(response.data);
            setChave(response.data.dados.CHAVE_ACESSO);          
            setRazaoEmitente(response.data.dados.RAZAO);
            setItens(response.data.dados.ITENS);
           }else{
            console.log('nao consegui localizar')
           }
            
           }                               
         )
         .catch(function (error) {
           console.log(error);
                   
         });  
      }

  return <div className="conteiner-fluid titulo ">
  <Navbar/>
      

  <ModalItens isOpen={isModalItensOpen}
                 onRequestClose={closeModalItens}
                 itens={itens}
                 abreAssociacao={abreAssociacao}                 
                 />

  <ModalAssociacao isOpen={isModalAssociacaoOpen}
                 onRequestClose={closeModalAssociacao}
                 prodAssociar={prodAssociar} 
                 codProduto={codProduto}
                 qtd={qtdArray}
                 produtos={produtos}
                 descProduto={descProduto}
                 openModalProduto={openModalProduto}
                                
                 />             

  <ModalProduto isOpen={isProdutoOpen}
                 onRequestClose={closeModalProduto}
                 listarPesquisa={filtraProdutos}
                 setPesquisaProduto={setPesquisaProduto}
                 pesquisaProduto={pesquisaProduto}
                 setCodProduto={setcodProduto}
                 setDescricao={setDescricao}
                 setVendaDefault={setVendaDefault}
                 abreLancaItem={abreLancaItem}
                 itens={produtosBusca}
                 titulo="pesquisa de Produto"
                 />                     
           
  <div className="offset-2 col-8">
   <div className="d-flex importacao">
  <h5>Entrada de Nota</h5> 
  
    <input type="file" accept=".xml" onChange={handleFileChange} />
    <button onClick={handleUpload} className='btn btn-secondary'>importar XML</button>
  </div>  

<hr />
 <div className="conteiner-fluid">
 <form className="row tela">
 
    <div className="col-2">
        <label htmlFor="inputAddress" id='form-label'  className="form-label">COMPRA</label>
        <input type="text" value={codCompra} className="form-control" id="inputCodigo" tabIndex="1" disabled/>
    </div>

    <div className="col-6">
        <label htmlFor="inputAddress" id='form-label'  className="form-label">CHAVE DE ACESSO</label>
        <input type="text" value={chave} className="form-control" id="inputCodigo" tabIndex="1"  disabled/>
    </div>

    <div className="col-2">
        <label htmlFor="inputAddress" id='form-label'  className="form-label">DATA</label>
        <input type="text"  value={dataAtual} id='data-label' className="form-control" disabled/>
    </div>

    <div className="col-2">
        <label htmlFor="inputAddress" id='form-label'  className="form-label">TOTAL</label>
         <NumericFormat className="form-control"
                             id="inputValorTotal" 
                             value= {totalPedido.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                             tabIndex="9"
                             allowLeadingZeros
                             decimalSeparator=','
                             thousandSeparator='.'
                             decimalScale={2}
                             prefix="R$ "
                             
                        />  
    </div>

    <hr className='mt-4'/>
    <h6>FORNECEDOR</h6>

    <div className="col-8">
        <label htmlFor="inputAddress" id='form-label'  className="form-label">RAZÃO</label>
        <input type="text" value={razaoEmitente} className="form-control" id="inputCodigo" tabIndex="1"  disabled/>
    </div>
    <div className="col-4">
        <label htmlFor="inputAddress" id='form-label'  className="form-label">CNPJ</label>
        <input type="text" value={cnpjEmitente} className="form-control" id="inputCodigo" tabIndex="1"  disabled/>
    </div>

    <div className="row mt-3 lista-produtos" >
      <h6>ITENS DA NOTA</h6>            
            
            <table id='inputCodigo' className="table table-hover table-striped">
            <thead>
            <tr>
                <th scope="col">CODIGO</th>
                <th scope="col">DESCRIÇÃO</th>
                <th scope="col">UNIDADE</th>
                <th scope="col">VALOR</th>
                <th scope="col">QTD</th>
                <th scope="col">TOTAL</th>
                <th scope="col">AÇÃO</th>

            </tr>
            </thead>
            
            <tbody className="table-group-divider">
            { produtos.map((p)=> {
                return <tr key={p.cProd}>
                    <td>{p.cProd}</td>          
                    <td>{p.xProd}</td>
                    <td>{p.uCom}</td>
                    <td>{Number(p.vUnCom).toFixed(2)}</td>
                    <td>{Number(p.qCom).toFixed(2)}</td>
                    <td>{Number(p.vProd).toFixed(2)}</td>
                    <td>
                    {p.proxforn.COD_BARRA ?null:(
                        <a  onClick={(e) => abreAssociacao(p.cProd)} onBlur={(e) =>(setQtd(p.qCom))}  >
                        <i class="bi bi-pen-fill"></i>
                        </a>)}                                        
                    </td>
                </tr>
            } )}  
            </tbody>
            
            </table>


           
         </div>

         <div className="col-12 footer-cart">
                   <hr />
                       <Link to="/app/estoque/compra" type="submit" className="btn btn-outline-primary butao"  tabIndex="14">Desistir</Link>
                       <button type="submit" className="btn btn-primary butao" tabIndex="13">Salvar</button>
                       

         </div>
    </form>
    </div>
  </div> 
  <FooterBar/>
  </div> 

  
}

export default NovaCompra