import React, { useState } from 'react';
import './login.css';
import { Navigate } from 'react-router-dom';
import {api} from '../../services/api';
import FundoMulher from '../../images/fundoMulher.png';
import Logo from '../../images/logoNovo.png'
import {vhostV1, vhostV2} from '../funcoes';

//import { AuthContext } from '../context/auth';

function Login(){

  const inputs = document.querySelectorAll("input, button");

// Adicione um manipulador de eventos para o evento "keydown" em cada entrada ou botão
inputs.forEach(input => {
  input.addEventListener("keydown", e => {
    // Se a tecla pressionada for Enter
    if (e.key === "Enter") {
      e.preventDefault(); // evita que a tecla "Enter" envie um formulário, se aplicável

      // Verifica se o elemento atual é um botão
      if (input.tagName.toLowerCase() === 'button') {
        input.click(); // Aciona o botão
      } else {
        // Obtenha o índice do elemento atual
        const currentIndex = Array.from(inputs).indexOf(e.target);
        
        // Mova o foco para o próximo elemento de entrada ou botão
        const nextIndex = currentIndex + 1;
        if (nextIndex < inputs.length) {
          inputs[nextIndex].focus();
        }
      }
    }
  });
});

   
    
    const [email,setEmail] = useState('');
    const [senha,setSenha] = useState('');
    const [iloja,setIloja] = useState('');
    const [sucesso,setSucesso] = useState('');
    const [loading,setLoading] = useState(false); 
    //const {setLogado} = React.useContext(AuthContext)



    function LoginUsuario(e){
         e.preventDefault()
         setLoading(true);      
        

         api.post(vhostV1('webcash/login'), {
          loja: iloja,
          user: email,
          pwd: senha
        })
        .then(function (response) {
          localStorage.setItem("loja",iloja);  
          localStorage.setItem("usuario",email);
          localStorage.setItem("colaborador",response.data.data.usuario);  
          localStorage.setItem("token",response.data.data.authorization);  
          
          setSucesso('S');
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          
          setSucesso('N');
          setLoading(false);
        });
        
      
    }

    {const event = new Date();
    
    // Expected output: "Wed Oct 05 2011 16:48:00 GMT+0200 (CEST)"
    // Note: your timezone may vary
    
    
    // Expected output: "2011-10-05T14:48:00.000Z"
    }

    
    return <div className='row'>
    <div className="col-sm-6 d-flex justify-content-center align-items-center text-center form-container">
         
      <form className="form-signin mt-7">
      <img src={Logo} alt=""  />
          <h1 className="h3 mb-3 fw-normal">Bem vindo</h1>
          <h6>acesse sua conta</h6>
   
         <div className="form-floating mb-3">
         
         <input type="text" 
                className="form-control" 
                id="lojaInput" 
                placeholder="Loja" 
                onChange={(e) => setIloja(e.target.value)}/>
          
        </div>

        <div className="form-floating mb-3">  
        
          <input type="email" 
                className="form-control" 
                id="floatingInput" 
                placeholder="Usuário" 
                onChange={(e) => setEmail(e.target.value)}/>
          
        </div>
        
         
        <div className="form-floating">
        
          <input type="password" 
                 className="form-control" 
                 id="floatingPassword" 
                 placeholder="Senha"
                 onChange={(e) => setSenha(e.target.value)} 
                 
           />
          
        </div>
        
        <button className="btn btn-primary btn-lg w-100" onClick={LoginUsuario}>
                    {loading ? <div className="spinner-border text-light" role="status">
                                <span className="visually-hidden">Loading...</span>
                                </div>:<span className="ms-2">Acessar</span>}
                    
                    </button>
        { sucesso === 'N' ?<div className="alert alert-danger" role="alert"> usuario ou senha incorreta!</div>:null}      
        { sucesso === 'S' ?<Navigate to ="/app/home" />:null}      

        
        <p className="mt-5 mb-3 text-muted">&copy; Desenvolvido por SRI automação</p>
      </form>
    </div>

    <div className='col-sm-6 px-0 d-none d-sm-block' >
      <img  className='backround-login'  src={FundoMulher} alt="WebLite" />

    </div>
    </div>
  }

export default Login;