import React,{useState,useEffect} from "react";
import api from "../../services/api";
import { vhostV1 } from "../funcoes";
import Navbar from "../navbar/navbar";
import { Link, Navigate } from "react-router-dom";
import './nfe.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useNavigate } from "react-router-dom";
import Loading from "../components/loading/Loading";
import WidgetNfe from "../components/widgetNfe/widgetNfe";
import customizeScrollbar from "customsb";
import FooterBar from "../footer/footerBar";




function Nfe(){

    const [texto,setTexto] = useState('');
    const [busca,setBusca] = useState("");
    const [notas,setNotas] = useState([]); 
    const [sResult,setSresult] = useState(0);
    const [erroEmissao,setErroEmissao] = useState(false);
    const [msgErro,setmsgErro] = useState('')
    const [confirmaEmissao,setConfirmaEmissao] = useState(false);
    const [load,setLoad] = useState(false);
    const [base64Data, setBase64Data] = useState('');
    const [dataInical,setDataInicial] = useState(new Date); 
    const [dataFinal,setDataFinal] = useState(new Date);

    let config = {
      headers: {
         'Authorization': localStorage.getItem("token")
         }
      }

      const navigate = useNavigate();


    // iniciar NFE
    function iniciarNfe(){
      api.post(vhostV1('webcash/nfe/incluir'), {
        empresa:0,
        idMesa: 0,
        NFE_B_CNF:-1,
        codColaborador: localStorage.getItem("colaborador")
        
       },config)
       .then(function (response) {  
          navigate("/app/novanfe/1/"+response.data.result);      
                
         }                               
       )
       .catch(function (error) {
         console.log(error);
        
        });
    
    }

     async function listarNotas(){
      let d = Date();
      let res = {};
      res =  await api.post(vhostV1('webcash/nfe/listaNfe'), {
        loja: localStorage.getItem('loja'),
        empresa: 0,
        status:'%',
        dtinicial:dataInical,
        dtfinal:dataFinal,
        cliente:0
      },config)
      console.log(res.data)
      if(res && res.data && res.data.dados){
        setNotas(res.data.dados); 
        setSresult(res.data.result); 
      }else{      
        console.log(res.data);                
      };
     }

      // Aplicando a personalização com opções diferentes
// Abaixo é somente um exemplo de personalização, adapte para o seu projeto
useEffect(() => {
  // O uso do useEffect garante que a função seja chamada apenas no lado do cliente
  customizeScrollbar({
    scrollbarWidth: "10px",
    trackBackground: "#333",
    thumbBackground:
      "linear-gradient(178.8deg, #ffa066 47.41%, #ff9666 65.18%, #ff783a 90.84%)",
    thumbBorderRadius: "10px",
  });
}, []);


    //buscar notas
    useEffect(function(){
      listarNotas();     
      
    },[])

    async function cencelaNFE(nfeChave) {     
     
        const res = await api.post(vhostV1('webcash/nfe/cancelaNfe'), {
          loja: localStorage.getItem('loja'),
          empresa: 0,
          chave: nfeChave
        },config);
        if (res.response.status===200) {
          try {
            
            setConfirmaEmissao(true)
            listarNotas()
          } catch (err){

          }         
        } else {
          setmsgErro(res.response.data.erro);
          setErroEmissao(true)
        }
    }   
   
    
    const emitirNFce = async(npedido)=>{
  
      const sJson = {}
        sJson.loja=localStorage.getItem('loja'); 
        sJson.empresa=0;
        sJson.nota=npedido;
        sJson.tipo=55;
              
      let res = {};
        res = await api.post(vhostV1('webcash/nfe/emitir/danfe'), sJson, config)        

        if (res.status===200) {
          setConfirmaEmissao(true);
          listarNotas();
        } else {
          setmsgErro(res.response.data.erro);
          setErroEmissao(true)
        }
      
    }


    async function fetchBase64Data(schave){
      try {
        setLoad(true)
          const response = await api.post(vhostV1('webcash/nfe/danfepdf'),{
          loja: localStorage.getItem('loja'),
          empresa: 0,
          chave:schave
        },config);
        setLoad(false)
        setBase64Data(response.data.dados.data.base64);
        convertAndOpenPDF(response.data.dados.data.base64);
      } catch (error) {
        console.error('Erro ao buscar base64 da API:', error);
      }
    };

    const convertAndOpenPDF = (base64String) => {
      const byteCharacters = atob(base64String);
      const byteArrays = [];
  
      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
  
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
  
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
  
      const pdfBlob = new Blob(byteArrays, { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);
  
      window.open(pdfUrl, '_blank');
    };

              

   return <div>
       <Navbar/>
       <div className="container-fluid titulo offset-lg-1 col-lg-10">
       
          <div className="d-flex justify-content-between">
             <h1 className="d-inline">Emissão de NFe</h1>
             <div>
                <button onClick={iniciarNfe} className="btn btn-primary btn-cli" type="button"><i className="bi bi-plus-circle-fill"></i> Incluir</button>
                {/* <button className="btn btn-secondary btn-cli" type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i className="bi bi-funnel-fill"></i>Filtros</button> */}
             </div>

            </div>
        


        

        <div className="row">
          <div className="col-4">
            {
            //<Link to={'/app/novanfe/1'} className="btn btn-primary btn-cli" type="button"><i className="bi bi-plus-circle-fill"></i> Cadastrar</Link>
            }
            
          </div>
        </div>  
        { load === true?<Loading/>:null}   
        <table className="table table-hover table-striped" id="table-nfe">
            <thead>
            <tr>
                <th scope="col">Status</th>
                <th scope="col">Sequencial</th>
                <th scope="col">N Nfe</th>
                <th scope="col">Destinatário</th>
                <th scope="col">Emissão</th>
                <th scope="col">Total</th>
                <th scope="col">Ação</th>
                </tr>
            </thead>
            <tbody className="table-group-divider">
              { notas.length>0?
                 notas.map((nota)=> {
                  return <tr key={nota.NFE_B_CNF}>                      
                      <td><button className={`btn btn-sm status-${nota.STATUS}`}>
                                   {nota.STATUS}
                                </button></td>
                      <td>{("000000000" + nota.NFE_B_CNF).slice(-9)}</td>          
                      <td>{("000000000" + nota.NFE_B_NNF).slice(-9)}</td>
                      <td>{nota.RAZAO}</td>
                      <td>{nota.NFE_B_DEMIT}</td>
                      <td>{nota.NFE_W_VNF.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                      
                      <td>
                         <div className="dropdown">
                            <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              Opções
                            </button>
                            <ul className="dropdown-menu">
                            
                            {nota.STATUS === "Autorizada"?
                              <>
                              <li><button className="dropdown-item" onClick={(e) => fetchBase64Data(e.target.value)} value={nota.NFE_A_ID}><i className="bi bi-printer-fill"></i>Imprimir Nota</button></li>
                              <li><button className="dropdown-item" onClick={(e) => cencelaNFE(e.target.value)} value={nota.NFE_A_ID}><i className="bi bi-x-circle-fill"></i>Cancelar Nota</button></li>
                              </>:
                              <><li><button onClick={(e) => emitirNFce(nota.NFE_B_CNF)} className="dropdown-item"><i className="bi bi-broadcast"></i>Gerar Nfe</button></li>
                              <li><Link to={'/app/novanfe/2/'+nota.NFE_B_CNF} className="dropdown-item"><i className="bi bi-pen-fill"></i>Editar Nfe</Link></li>
                              <li><button onClick={(e) => cencelaNFE(nota.NFE_B_CNF)}  className="dropdown-item"><i className="bi bi-trash-fill"></i>Cancelar Nfe</button></li>
                              <li><a href="#" className="dropdown-item"><i className="bi bi-send-fill"></i>Enviar Lote</a></li>
                              <li><a href="#" className="dropdown-item"><i className="bi bi-search"></i>Busca Protocolo</a></li>
                              </>}
                            </ul>
                         </div>
                      </td>
                  </tr>
              } ):null}  
            </tbody>
          </table>


          {
           erroEmissao ? 
           <SweetAlert
              danger
              confirmBtnText="OK"
              confirmBtnBsStyle="danger"
              title="Erro"
              onConfirm={() => setErroEmissao(false)}                            
            >
              {msgErro}
            </SweetAlert>:null
           
          }


{
           confirmaEmissao ? 
           <SweetAlert
              success                         
              confirmBtnText="OK"
              confirmBtnBsStyle="success"
              title="Parabéns"
              onConfirm={() => setConfirmaEmissao(false)}                            
            >
              Nota Emitida com sucesso!
            </SweetAlert>:null
           
          }

        
        
       

   </div>
   <FooterBar/>
   </div>


}


export default Nfe;