import React, { useState, useEffect } from 'react';
import { api } from '../../services/api.js';
import { Link } from 'react-router-dom';
import Navbar from '../navbar/navbar.jsx'
import { vhostV1 } from '../funcoes.js';
import BuscaVazaia from '../components/buscavazia/buscaVazia.jsx';
import customizeScrollbar from "customsb";
import FooterBar from '../footer/footerBar.jsx';




function EntradaEstoque() {  


     const [pedidos,setPedidos] = useState([]);
     const [sResult,setSresult] = useState(0);
     const [tipo,setTipo] = useState('T');
     const [dataInical,setDataInicial] = useState(new Date); 
     const [dataFinal,setDataFinal] = useState(new Date); 
     
     
     

    let config = {
      headers: {
         'Authorization': localStorage.getItem("token")
         }
      }

      const getDadosRelatorio = async()=>{
        
        let response = {};
        try {
          response = await api.post(vhostV1('webcash/vendas/relsintetico'),{
            loja: localStorage.getItem('loja'),
            empresa: 0,
           //dtinicial:"2023-02-13T13:45:00.000Z",
            dtinicial:dataInical,
            dtfinal:dataFinal,
            tipo:tipo
            
          }, config)
          
        } catch (error) {
          console.log(error);          
        }
         
        return response.data.dados;
      
        //setItens(response.data.dados); 
        //setResultItens(response.data.result)
        
      
      
      }

           // Aplicando a personalização com opções diferentes
// Abaixo é somente um exemplo de personalização, adapte para o seu projeto
useEffect(() => {
  // O uso do useEffect garante que a função seja chamada apenas no lado do cliente
  customizeScrollbar({
    scrollbarWidth: "10px",
    trackBackground: "#333",
    thumbBackground:
      "linear-gradient(178.8deg, #ffa066 47.41%, #ff9666 65.18%, #ff783a 90.84%)",
    thumbBorderRadius: "10px",
  });
}, []);


      async function gerarRelatorio () { 

        const tItens = await getDadosRelatorio();
//          PedidosPdf(tItens);
      //console.log(data)
      // ProductReport();
      
               
      }  


    function filtrarPedidos(){

      //const dt = new Date(dataInical);

      //const iso = dt.toISOString();
      //console.log(iso); // 👉️ "2022-07-21T00:00:00.000Z"


      api.post(vhostV1('webcash/dashboard/listapedidos'), {
        loja: localStorage.getItem('loja'),
        empresa: 0,
       //dtinicial:"2023-02-13T13:45:00.000Z",
        dtinicial:dataInical,
        dtfinal:dataFinal,
        tipo:tipo
        },config)
      .then(function (response) {   
        setPedidos(response.data.dados); 
        
        
        setSresult(response.data.result); 
    
      })
      .catch(function (error) {
        console.log(error);
                
      });
      
       

    }  



   //buscar pedidos
    useEffect(function(){
      api.post(vhostV1('webcash/produto/listaentrada'), {
        loja: localStorage.getItem('loja'),
        empresa: 0,
        dtinicial:dataInical,
        dtfinal:dataFinal,
        lancado:tipo        
      },config)
      .then(function (response) {        
        setPedidos(response.data.dados); 
        setSresult(response.data.result); 
    
      })
      .catch(function (error) {
        console.log(error);
                
      });
      
      
    },[])
   
      

    return <div>

        <Navbar/>
        <div className="container-fluid titulo offset-lg-2 col-lg-8">
        <h2 className="d-inline">Entrada de Estoque </h2>
        <hr />
        <div className="row mb-3">
           <div className="d-flex justify-content-between">
                <div>                
                <Link type="button" className="btn btn-primary btn-cli"  to="/app/estoque/novaentrada"><i className="bi bi-plus-circle-fill"></i> Entrada</Link>
                {/*<button type="button" className="btn btn-secondary btn-cli" data-bs-toggle="modal" data-bs-target="#moda-filtro"><i className="bi bi-funnel-fill"></i>Filtros</button>
                <button type="button" className="btn btn-danger btn-cli"  data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i className="bi bi-printer-fill"></i> Relatório</button>
                <SalesReport/>*/}
                </div>

                
                
            </div> 
        </div>   

        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-sm">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="staticBackdropLabel">Relátorio de Vendas</h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <div className="contnt-modal">
                   <h5>Tipo do relatório </h5> 
                  <div className="form-check">
                    <input className="form-check-input" value={0}  type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                      Sintético
                    </label>
                  </div>
                 

                  <div className="form-check">
                    <input className="form-check-input" value={2}  type="radio" name="flexRadioDefault" id="flexRadioDefault2"/>
                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                      Finalizadora
                    </label>
                  </div>

                    
                    <hr />

                   <div className="row">
                     <h5>Período  </h5>   
                       <div className="datas col-8 offset-2">
                         <input type="date" className='form-control'/>
                         <br />
                         <input type="date" className='form-control'/>
                         </div>
                          
                     </div>

                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                              
                  { sResult === 1?
                  <div>                  
                  <button type="button" onClick={ async()=> await gerarRelatorio()} className="btn btn-primary btn-cli" data-bs-dismiss="modal">Imprimir</button>
                  </div>
                  :null} 
                  

                </div>
              </div>
            </div>
          </div>


          <div className="modal fade" id="moda-filtro" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="staticBackdropLabel">Filtros</h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <div className="contnt-modal">
                                      
                  

                   <div className="row">
                    <div>
                     <h5>Tipo  </h5>   
                     <div>
                     <div className="form-control d-inline me-3">
                        <select name="status" id="status" onChange={(e)=> setTipo(e.target.value)}>
                            <option value="TODOS">Todos</option>
                            <option value="RECIBO">Recibo</option>
                            <option value="NFCE">NFCe</option>
                        </select>
                    </div>
                    
                   </div>
                   </div>

                   <div>

                   <h5>Caixa  </h5>   
                     <div>
                     <div className="form-control d-inline me-3">
                        <select name="status"  id="status" >
                            <option value="%">Todos</option>
                            <option value="001">001</option>
                            <option value="002">002</option>
                            <option value="003">003</option>
                            <option value="004">004</option>
                        </select>
                     </div>
                    
                     </div>

                     </div>
                          
                     </div>
                     <br />

                     <div className="row">
                    
                    </div>


                    <div className="row">
                     <h5>Período</h5>   
                     <div>
                     <div className='d-flex col-12'>           
                        <input type="date" onChange={(e)=> setDataInicial(e.target.value+"T10:10:00.000Z")} className="form-control" />
                         
                        <input type="date" onChange={(e)=> setDataFinal(e.target.value+"T10:10:00.000Z")} className="form-control" />
                    </div>
                     </div>
                     </div>

                  </div>
                </div>
                <div className="modal-footer">
                  <div>                  
                  <button type="button" onClick={filtrarPedidos} className="btn btn-secondary btn-cli" data-bs-dismiss="modal">Ativar</button>
                  <button type="button" onClick={async()=> await gerarRelatorio()} className="btn btn-success btn-cli" data-bs-dismiss="modal">Imprimir</button>
                  </div>

                  

                </div>
              </div>
            </div>
          </div>

       { sResult === 1?
        <table id='table-estoque' className="table table-hover table-striped">
            <thead>
            <tr>
            <th scope="col">PEDIDO</th>
                <th scope="col">DATA</th>
                <th scope="col">CANCELADO</th>
                <th scope="col">VALOR</th>
                <th scope="col">AÇÃO</th>
                </tr>
            </thead>
            <tbody className="table-group-divider">
            {  
            pedidos.map((pedido) => {
                return <tr key={pedido.id}>
                       <td>{("0000" + pedido.id).slice(-6)}</td>
                       <td>{pedido.data}</td>
                       <td>{pedido.cancelado}</td>
                       <td> R$ {Number(pedido.valor).toFixed(2)}</td>
                                             
                       <td><Link to='#'><i className="bi bi-search"></i></Link></td>

                       </tr>
              } )}  
            </tbody>
          </table>
          :<BuscaVazaia/>} 
     
 
        

      </div>     
      <FooterBar/> 
    </div>
}


export default EntradaEstoque;