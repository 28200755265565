import React from 'react';
import PropTypes from 'prop-types';

// Componente Card
const CardNfe = ({ title, value, style }) => {
  return (
    <div className="col-xl-4 col-md-4 mb-3">
      <div className="card shadow h-100 py-2" style={style}>
        <div className="card-body">
          <div className="row no-gutters align-items-center">
           <div className="card text-center flex-grow-1">
             <div className="col mr-2">
      
             <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
             {title}</div>
             <div className="h6 mb-0 font-weight-bold text-gray-800">{value}</div>
             </div>
           
           </div>
           </div>
          </div>
        </div>
      </div>
    
  );
};

// Definindo os tipos de props esperados
CardNfe.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default CardNfe;