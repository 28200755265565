import React, { useRef, useState } from 'react'
import Modal from 'react-modal/lib/components/Modal';
import './ModalAdicaoItem.css'
import { NumericFormat } from 'react-number-format';
import { useEffect } from 'react';
import api from '../../../services/api';
import { vhostV1 } from '../../funcoes';
import SweetAlert from 'react-bootstrap-sweetalert';
import ModalProduto from '../modalProduto/ModalProduto';
import customizeScrollbar from 'customsb';




function ModalAdicaoItem(props){
  const inputs = document.querySelectorAll("input, select");
    // Adicione um manipulador de eventos para o evento "keydown" em cada entrada ou select
    inputs.forEach(input => {
      input.addEventListener("keydown", e => {
        // Se a tecla pressionada for Enter
        if (e.key === "Enter") {
          e.preventDefault(); // evita que a tecla "Enter" envie um formulário, se aplicável

          // Obtenha o índice do elemento atual
          const currentIndex = Array.from(inputs).indexOf(e.target);
  
          // Mova o foco para o próximo elemento de entrada ou select
          const nextIndex = currentIndex + 1;
          if (nextIndex < inputs.length) {
            inputs[nextIndex].focus();
          }
        }
      });
    }); 


    useEffect(() => {
      // O uso do useEffect garante que a função seja chamada apenas no lado do cliente
      customizeScrollbar({
        scrollbarWidth: "10px",
        trackBackground: "#333",
        thumbBackground:
          "linear-gradient(178.8deg, #ffa066 47.41%, #ff9666 65.18%, #ff783a 90.84%)",
        thumbBorderRadius: "10px",
      });
    }, []);

  let config = {
    headers: {
      'Authorization': localStorage.getItem("token")
    }
  }

  const [descricao,setDescricao] = useState('')
  const [venda,setVenda] = useState(0);
  const [vendaDefault,setVendaDefault] = useState(0);
  const [qtd,setQtd] = useState(1);
  const [desconto,setDesconto] = useState(0);
  const [descontoDefault,setDescontoDefault] = useState(0);
  const [total,setTotal] = useState(0);
  const [msgErro, setmsgErro] = useState('');
  const [erroValidacao, setErrovalidacao] = useState(false);
  const [isProdutoOpen,setIsProdutoOpen] = useState(false);
  const [codProduto,setCodProduto] = useState('');
  const [ncm, setNcm] = useState("");
  const [und, setUnd] = useState('');
  const [seguro, setSeguro] = useState(0);
  const [qtdEmbalagem, setQtdEmbalagem] = useState(1);
  const [qtdTributada, setQtdTributada] = useState(1);
  const [precoEmbalagem, setPrecoEmbalagem] = useState(0);
  const [frete, setFrete] = useState(0);
  const [outrasDesp, setOutrasDesp] = useState(0);
  const [codEmbalagem, setCodEmbalagem] = useState(0);
  const [cfop, setCfop] = useState('');
  const [cstIpi, setCstIpi] = useState(0);
  const [bcIpi, setBcIpi] = useState(0);
  const [aliquotaIpi, setAliquotaIpi] = useState(0);
  const [valorIpi, setValorIpi] = useState(0);
  const [st, setSt] = useState('');
  const [cest, setCest] = useState('');

  const [cstIcms, setCstIcms] = useState(0);
  const [IcmsStRet, setIcmsStRet] = useState(0);
  const [BcIcmsRet, setBcIcmsRet] = useState(0);
  const [icmsDesonerado, setIcmsDesonerado] = useState(0);
  const [redBaseCalc, setRedBaseCalc] = useState(0);
  const [valorIcmsNormal, setValorIcmsNormal] = useState(0);
  const [aliquotaIcms, setAliquotaIcms] = useState(0);
  const [baseCalcIcms, setBaseCalcIcms] = useState(0);

  const [MvaIcms, setMvaIcms] = useState(0);
  const [redBaseCalcSt, setRedBaseCalcSt] = useState(0);
  const [baseIcmsSubst, setBaseIcmsSubst] = useState(0);
  const [aliquotaIcmsSubst, setAliquotaIcmsSubst] = useState(0);
  const [valorIcmsSubst, setValorIcmsSubst] = useState(0);

  const [pisCst, setPisCst] = useState(0);
  const [bcPis, setBcPis] = useState(0);
  const [aliquotaPis, setAliquotaPis] = useState(0);
  const [valorPis, setValorPis] = useState(0);

  const [confinsCst, setConfinsCst] = useState(0);
  const [bcConfins, setBcConfins] = useState(0);
  const [aliquotaConfis, setAliquotaConfins] = useState(0);
  const [valorConfins, setValorConfins] = useState(0);
  const [linhaOcultaRef, setLinhaOcultaRef] = useState(null);
  const [modalAberto, setModalAberto] = useState(false);

  useEffect(() => {
   
    setBaseCalcIcms(total);
    setBcConfins(total);
    setBcIpi(total);
    setBcPis(total);
   
  }, [total]);
  
  
  const refLinha = useRef(null);
  const refLinha2 = useRef(null);
  const hrRef = useRef(null);
  const hrRef2 = useRef(null);
 
  
  console.log('props:' + JSON.stringify(props));
  
  useEffect(() =>{
    if(props.isOpen === true){
      setModalAberto(true);
    }else if(props.isOpen === false){
      setModalAberto(false)
    }
  },[props.isOpen])


    useEffect(() => {
      if (modalAberto === true) {
        // Verifica a condição de `finalidadeEmissao` e aplica o estilo necessário
        if (Number(props.finalidadeEmissao) === 1 || Number(props.finalidadeEmissao) === 2 || Number(props.finalidadeEmissao) === 3) {
          refLinha.current.style.display = 'flex';
          refLinha2.current.style.display = 'flex';
          hrRef.current.style.display = 'flex';
          hrRef2.current.style.display = 'flex';
        } else if (Number(props.finalidadeEmissao) === 4 || Number(props.finalidadeEmissao) === 5) {
          refLinha.current.style.display = 'none';
          hrRef.current.style.display = 'none';
          hrRef2.current.style.display = 'none';
          refLinha2.current.style.display = 'none';
        }
      }
    }, [modalAberto]); 


  function cancelaAdicao(){
    setCodProduto('');
    setDescricao('');
    setVenda(0);
    setVendaDefault(0);
    setTotal(0);
    setQtd(1);
    setDesconto(0);
  }


  function openModalProduto(){
    setIsProdutoOpen(true);
    
 }

 function closeModalProduto(){
      setIsProdutoOpen(false);
    

   
 }

 

  


  function calculaPreco(){

    setQtdTributada(qtd * qtdEmbalagem);
    setTotal(Number(qtdTributada) * Number(vendaDefault) - Number(desconto) + Number(seguro) + Number(frete) + Number(outrasDesp));
    
 }

 function calculaImposto(){
  setValorIpi(Number(bcIpi) * Number(aliquotaIpi)/100);
  setValorIcmsNormal(Number(baseCalcIcms) * Number(aliquotaIcms)/100);
  setValorPis(Number(bcPis) * Number(aliquotaPis)/100);
  setValorConfins(Number(bcConfins) * Number(aliquotaConfis)/100);
  setValorIcmsSubst(Number(baseIcmsSubst) * Number(aliquotaIcmsSubst)/100);

 }

 async function gravaItem(){
  
  await adicionaItem();    
  setDescricao('');
  setQtd('');
  setTotal('');
  setVenda(''); 
  setDesconto(0); 
  props.onRequestClose();
 }

  async function adicionaItem(){ 
    const sjson ={
        COD_PRODUTO:codProduto,
        UNIDADE:und,
        QTD:qtdTributada,
        VENDA:vendaDefault,
        COMPRA:1,
        DESCONTO:desconto,
        TOTAL:Number(total),
        NCM:ncm,
        CFOP:cfop,
        ALIQUOTA:"18",
        DOCUMENTO:props.idVenda,
        COD_COLABORADOR:localStorage.getItem("colaborador"),
        RESPONSAVEL:10,
        ALI_ICMS:Number(aliquotaIcms),
        ST:st,
        CEST:cest,
        IPI:53,
        ALI_IPI:Number(aliquotaIpi),
        SEGURO:Number(seguro),
        FRETE:Number(frete),
        ST_PISCOFINS:1,
        ALI_PIS:Number(aliquotaPis),
        ALI_COFINS:Number(aliquotaConfis),
        VLRPIS:Number(valorPis),
        VLRCOFINS:Number(valorConfins),
        BC_RED_ST:Number(redBaseCalcSt),
        ST_IPI:53,
        OUTRAS_DESP_AC:Number(outrasDesp),
        ICMSDESONERADO:Number(icmsDesonerado),
        ALIQ_ICMS:Number(aliquotaIcms),
        BC_ICMS:Number(baseCalcIcms),
        ESTADUAL:1,
        MVA:Number(MvaIcms),
        VLR_BCSUBST:Number(baseIcmsSubst),
        VLR_ICMSSUBST:Number(valorIcmsSubst),
        BC_STRET:0,
        DESC_FIN:0,
        REDBC:Number(redBaseCalc),
        VLR_IPI:Number(valorIpi),
        ALIQUOTA_IPI:Number(aliquotaIpi),
        VLR_COFINS:Number(valorConfins),
        VLR_PIS:Number(valorPis),
        ICMS_DESONERADO:Number(icmsDesonerado),
        IPI_CST:Number(cstIpi),
        BC_IPI: Number(bcIpi),
        CST_ICMS:Number(cstIcms),
        VLR_ICMS: Number(valorIcmsNormal),
        BC_ICMS_RET: Number(BcIcmsRet),
        ICMS_STRET: Number(IcmsStRet),
        ALI_ICMS_SUB: Number(aliquotaIcmsSubst),
        PIS_CST: Number(pisCst),
        BC_PIS: Number(bcPis),
        CST_COFINS: Number(confinsCst),
        BC_CONFINS: Number(bcConfins)
         };
        let res = {};       
        try {
          res = await api.post(vhostV1('webcash/nfe/incluiritem'),sjson,config)
          console.log('retorno api'+res.data) 
          console.log('retorno api'+res)    
          if(res && res.data && res.data.dados ){ 
            console.log(res)            
          }else{
            console.log('erro no response de produtos '+res)
          }
        } catch (error) {

          console.log(error);
          }
          
          

          }
        
 // funcao para buscar produto
 function buscaIten(){
  api.post(vhostV1('webcash/produto/retornaproduto/'+codProduto), {
    loja: localStorage.getItem('loja'),
    empresa: 0         
  },config)
  .then(function (response) { 
    
   if(response && response.data && response.data.dados){
    console.log(response.data.dados);
    
    setDescricao(response.data.dados.DESCRICAO);
    setVendaDefault(Number(response.data.dados.VENDA));
    setVenda(Number(response.data.dados.VENDA));
    setUnd(response.data.dados.UNIDADE);
    setCstIpi(response.data.dados.CSTIPI);
    setNcm(response.data.dados.COD_NCM);
    setAliquotaIpi(response.data.dados.ALI_IPI);
    setAliquotaPis(response.data.dados.ALI_PIS_DEBITO);
    setAliquotaConfins(response.data.dados.ALI_COFINS_DEBITO);
    setCest(response.data.dados.CEST);
    setSt(response.data.dados.ST);
    if(props.tipoCfop === 'in'){
      setCfop(response.data.dados.CFOP_IN);
      setAliquotaIcms(response.data.dados.ICMS_IN);
    }else if(props.tipoCfop === 'out'){
      setCfop(response.data.dados.CFOP_OUT);
      setAliquotaIcms(response.data.dados.ICMS_OUT);
    }

    
    
    // setQtdEmbalagem(response.data.dados.QTD_EMBALAGEM)
     }
   else{
     console.log("não busquei")
   }  
    
   })
  .catch(function (error) {
   
    console.log(error);
            
  });
   };


  return <Modal isOpen={props.isOpen}
                onRequestClose={props.onRequestClose}
                overlayClassName="react-modal-overlay"
                ariaHideApp={false}
                class="react-modal-content">
           <ModalProduto isOpen={isProdutoOpen}
                 onRequestClose={closeModalProduto}
                 setCodProduto={setCodProduto}
                 setDescricao={setDescricao}
                 setVendaDefault={setVendaDefault}
                 setNcm={setNcm}
                 titulo="Pesquisa de Produto"
                 />         
          <div className="container-fluid pesquisa-modal">
        

            <div className="row">
                <h4>{props.titulo}</h4> 
                <hr />  
                  <div className="modal-body">
                  <div className="row">
                    
                    <div className="col-2">
                    <label htmlFor="inputAddress" className="form-label">CODIGO</label>
                    <div className="input-group mb-3">
                    <input name='inputCod' id='inputCod' value={codProduto} onChange={(e) => setCodProduto(e.target.value)} onBlur={(e) => buscaIten(e)} type="text" className="form-control"  aria-describedby="button-addon2" />
                        <button  className="btn btn-primary" onClick={(e) => openModalProduto()} type="button" id="button-addon2"><i style={{fontSize: "15px"}} className="bi bi-search "></i></button>
                    </div>    
                    </div>
                          
                  
                  <div className="col-5">
                  <label htmlFor="inputAddress" className="form-label">DESCRICAO</label>   
                  <input  type="text" onBlur={buscaIten(codProduto)}  value={descricao} onChange={(e) => setDescricao(e.target.value)} className="form-control" id="inputDescricao" tabIndex="2" required autoComplete="off"/>
                  </div>
                  <div className="col-2">
                  <label htmlFor="inputAddress" className="form-label">NCM</label>   
                  <input  type="text" onBlur={calculaPreco} value={ncm} onChange={(e) => setNcm(e.target.value)} className="form-control" id="inputDescricao" tabIndex="2" required autoComplete="off"/>
                  </div>

                
                  {/* <div className="col-3">
                  <label htmlFor="inputAddress" className="form-label">COD. EMBALAGEM</label>   
                  <input  type="text" onBlur={calculaPreco} value={codEmbalagem} onChange={(e) => setCodEmbalagem(e.target.value)} className="form-control" id="inputDescricao" tabIndex="2" required autoComplete="off"/>
                  </div> */}

                  <div className="col-1">
                  <label htmlFor="inputAddress" className="form-label">CFOP</label>   
                  <input  type="text" onBlur={calculaImposto} value={cfop} onChange={(e) => setCfop(e.target.value)} className="form-control" id="inputDescricao" tabIndex="2" required autoComplete="off"/>
                  </div>

                  <div className="col-2">
                  <label htmlFor="inputState" className="form-label">UND</label>
                     <input id="inputUnidade" value={und} onChange={(e)=> setUnd(e.target.value)} className="form-control" tabIndex="3" readOnly />
                  </div>
                  </div>

                  <hr />

         

                  
                  <div className="row">                     
                  <div className="col-3">
                  <label htmlFor="inputAddress" className="form-label">QUANTIDADE</label>   
                  <NumericFormat className="form-control"
                              id="inputVenda" 
                              onBlur={calculaPreco} 
                              value={qtd.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                              
                              allowLeadingZeros
                              decimalSeparator=','
                              thousandSeparator='.'
                              onValueChange={(values,suorceInfo)=>{
                                setQtd(values.value);
                              }}
                                                       
               />   
                  </div>
                  <div className="col-3">
                  <label htmlFor="inputAddress" className="form-label">QUANTIDADE EMBALAGEM</label>   
                  <NumericFormat className="form-control"
                              id="inputVenda" 
                              onBlur={calculaPreco} 
                              value={qtdEmbalagem.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                              
                              allowLeadingZeros
                              decimalSeparator=','
                              thousandSeparator='.'
                              decimalScale={3}
                              fixedDecimalScale={3}
                              onValueChange={(values,suorceInfo)=>{
                                setQtdEmbalagem(values.value);
                              }}
                                                       
               />   
                  </div>

                  <div className="col-2">
                  <label htmlFor="inputAddress" className="form-label">VENDA</label>   
                       <NumericFormat className="form-control"
                                      id="inputVenda" 
                                      value={vendaDefault.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                                      tabIndex="3"
                                      allowLeadingZeros
                                      decimalSeparator=','
                                      thousandSeparator='.'
                                      decimalScale={2}
                                      onBlur={calculaPreco}
                                      readOnly
                                                              
                       />
                       </div>

                  <div className="col-2"  >
                    <label htmlFor="inputAddress" className="form-label">DESCONTO</label>   
                   <NumericFormat className="form-control"
                              id="inputVenda" 
                              onBlur={calculaPreco} 
                              value={desconto.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                              
                              allowLeadingZeros
                              decimalSeparator=','
                              thousandSeparator='.'
                              decimalScale={2}
                              onValueChange={(values,suorceInfo)=>{
                                setDesconto(values.value);
                              }}
                                                       
               />   
          </div>
                  <div className="col-2">
                  <label htmlFor="inputAddress" className="form-label">SEGURO</label>   
                  <NumericFormat className="form-control"
                              id="inputVenda" 
                              onBlur={calculaPreco} 
                              value={seguro.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                              
                              allowLeadingZeros
                              decimalSeparator=','
                              thousandSeparator='.'
                              decimalScale={2}
                              onValueChange={(values,suorceInfo)=>{
                                setSeguro(values.value);
                              }}
                                                       
               />   
                  </div>

                  <div className="col-3">
                  <label htmlFor="inputAddress" className="form-label">QUANTIDADE TRIBUTADA</label>   
                  <NumericFormat className="form-control"
                              id="inputVenda" 
                              onBlur={calculaPreco} 
                              value={qtdTributada}
                              
                              allowLeadingZeros
                              decimalSeparator=','
                              thousandSeparator='.'
                              decimalScale={3}
                              fixedDecimalScale={3}
                              onValueChange={(values,suorceInfo)=>{
                                setQtdTributada(values.value);
                              }}
                              readOnly
                                                       
               />   
                  </div>

                  {/* <div className="col-3">
                  <label htmlFor="inputAddress" className="form-label">PREÇO EMBALAGEM</label>   
                  <input type="text" value={precoEmbalagem} onBlur={calculaPreco} onChange={(e) => setPrecoEmbalagem(e.target.value)}className="form-control" id="inputCodigo" tabIndex="4" required autoComplete="off"/>
                  </div> */}

                  <div className="col-3">
                  <label htmlFor="inputAddress" className="form-label">FRETE</label>   
                  <NumericFormat className="form-control"
                              id="inputVenda" 
                              onBlur={calculaPreco} 
                              value={frete.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                              
                              allowLeadingZeros
                              decimalSeparator=','
                              thousandSeparator='.'
                              decimalScale={2}
                              onValueChange={(values,suorceInfo)=>{
                                setFrete(values.value);
                              }}
                                                       
               />   
                  </div>

                  <div className="col-3">
                  <label htmlFor="inputAddress" className="form-label">OUTRAS DESPESAS E ACESSÓRIOS</label>   
                  <NumericFormat className="form-control"
                              id="inputVenda" 
                              onBlur={calculaPreco} 
                              value={outrasDesp.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                              
                              allowLeadingZeros
                              decimalSeparator=','
                              thousandSeparator='.'
                              decimalScale={2}
                              onValueChange={(values,suorceInfo)=>{
                                setOutrasDesp(values.value);
                              }}
                                                       
               />   
                  </div>



                  <div className="col-3">
                  <label htmlFor="inputAddress" className="form-label">TOTAL</label>   
                        <NumericFormat className="form-control"
                                      id="inputVenda"
                                      onBlur={calculaPreco} 
                                      defaultValue={total.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                                      tabIndex="3"
                                      allowLeadingZeros
                                      decimalSeparator=','
                                      thousandSeparator='.'
                                      decimalScale={2}
                                      value={total.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                                      readOnly
                                                             
                       />
                  </div>

                  </div>
                  </div>
                  </div>
                  
                 

                  <hr />
                  <div className='row' >
                  <div className="col-12" ref={refLinha} style={{ display: 'none', flexWrap: 'wrap', marginLeft: -10, marginRight: -10 }} id="linhaOculta">
                     <div className="col-3">
                     <label htmlFor="inputAddress" className="form-label">IPI/CST - SITUAÇÃO TRIB</label>   
                     <input type="text" value={cstIpi} onBlur={calculaImposto} onChange={(e) => setCstIpi(e.target.value)}className="form-control" id="inputCodigo" tabIndex="4" required autoComplete="off"/>
                     </div>

                     <div className="col-3">
                     <label htmlFor="inputAddress" className="form-label">BC IPI</label>   
                     <NumericFormat 
                       className="form-control"
                       id="inputVenda"
                       onBlur={calculaImposto} 
                       defaultValue={bcIpi}
                       value={bcIpi !== undefined ? bcIpi.toLocaleString("pt-BR", {style: "currency", currency: "BRL"}) : ''}
                       tabIndex="9"
                       decimalSeparator=","
                       thousandSeparator="."
                       decimalScale={2}
                       onValueChange={(values, sourceInfo) => {
                       setBcIpi(values.floatValue);
                       }}  
             
                          />
                     </div>

                     <div className="col-3">
                     <label htmlFor="inputAddress" className="form-label">ALIQUOTA IPI (%)</label>   
                     <NumericFormat 
                       className="form-control"
                       id="inputVenda"
                       onBlur={calculaImposto} 
                       defaultValue={aliquotaIpi}
                       value={aliquotaIpi !== undefined ? aliquotaIpi.toLocaleString("pt-BR", {style: "currency", currency: "BRL"}) : ''}
                       tabIndex="9"
                       decimalSeparator=","
                       thousandSeparator="."
                       decimalScale={2}
                       onValueChange={(values, sourceInfo) => {
                       setAliquotaIpi(values.floatValue);
                       }}  
             
                          />
                     </div>

                     <div className="col-3">
                     <label htmlFor="inputAddress" className="form-label">VALOR IPI</label>   
                     <NumericFormat 
                       className="form-control"
                       id="inputVenda" 
                       defaultValue={valorIpi}
                       value={valorIpi !== undefined ? valorIpi.toLocaleString("pt-BR", {style: "currency", currency: "BRL"}) : ''}
                       tabIndex="9"
                       decimalSeparator=","
                       thousandSeparator="."
                       decimalScale={2}
                       onValueChange={(values, sourceInfo) => {
                       setValorIpi(values.floatValue);
                       }}  
             
                          />
                     </div>
                     
                  </div>
                     
                  </div>

                  <hr ref={hrRef} style={{ display: 'none' }}/>
                  
                  <div className='row'>
                     <div className="col-3">
                     <label htmlFor="inputAddress" className="form-label">ICMS/CST - SITUAÇÃO TRIB</label>   
                     <input type="text" value={cstIcms !== 0 ? cstIcms: '0000'} onBlur={calculaImposto} onChange={(e) => setCstIcms(e.target.value)}className="form-control" id="inputCodigo" tabIndex="4" required autoComplete="off"/>
                     </div>

                     <div className="col-3">
                     <label htmlFor="inputAddress" className="form-label">BASE CALC ICMS</label>   
                     <NumericFormat 
                       className="form-control"
                       id="inputVenda"
                       onBlur={calculaImposto} 
                       defaultValue={baseCalcIcms}
                       value={baseCalcIcms !== undefined ? baseCalcIcms.toLocaleString("pt-BR", {style: "currency", currency: "BRL"}) : ''}
                       tabIndex="9"
                       decimalSeparator=","
                       thousandSeparator="."
                       decimalScale={2}
                       onValueChange={(values, sourceInfo) => {
                       setBaseCalcIcms(values.floatValue);
                       }}  
             
                          />
                     </div>

                     <div className="col-3">
                     <label htmlFor="inputAddress" className="form-label">ALIQUOTA ICMS (%)</label>   
                     <NumericFormat 
                       className="form-control"
                       id="inputVenda"
                       onBlur={calculaImposto} 
                       defaultValue={aliquotaIcms}
                       value={aliquotaIcms !== undefined ? aliquotaIcms.toLocaleString("pt-BR", {style: "currency", currency: "BRL"}) : ''}
                       tabIndex="9"
                       decimalSeparator=","
                       thousandSeparator="."
                       decimalScale={2}
                       onValueChange={(values, sourceInfo) => {
                       setAliquotaIcms(values.floatValue);
                       }}  
             
                          />
                     </div>

                     <div className="col-3">
                     <label htmlFor="inputAddress" className="form-label">VALOR ICMS NORMAL</label>   
                     <NumericFormat 
                       className="form-control"
                       id="inputVenda"
                       onBlur={calculaImposto} 
                       defaultValue={valorIcmsNormal}
                       value={valorIcmsNormal !== undefined ? valorIcmsNormal.toLocaleString("pt-BR", {style: "currency", currency: "BRL"}) : ''}
                       tabIndex="9"
                       decimalSeparator=","
                       thousandSeparator="."
                       decimalScale={2}
                       onValueChange={(values, sourceInfo) => {
                       setValorIcmsNormal(values.floatValue);
                       }}  
             
                          />
                     </div>

                     <div className="col-3">
                     <label htmlFor="inputAddress" className="form-label">RED. BASE CALCULO</label>   
                     <NumericFormat 
                       className="form-control"
                       id="inputVenda"
                       onBlur={calculaImposto} 
                       defaultValue={redBaseCalc}
                       value={redBaseCalc !== undefined ? redBaseCalc.toLocaleString("pt-BR", {style: "currency", currency: "BRL"}) : ''}
                       tabIndex="9"
                       decimalSeparator=","
                       thousandSeparator="."
                       decimalScale={2}
                       onValueChange={(values, sourceInfo) => {
                       setRedBaseCalc(values.floatValue);
                       }}  
             
                          />
                     </div>

                     <div className="col-3">
                     <label htmlFor="inputAddress" className="form-label">ICMS DESONERADO</label>   
                     <NumericFormat 
                       className="form-control"
                       id="inputVenda"
                       onBlur={calculaImposto} 
                       defaultValue={icmsDesonerado}
                       value={icmsDesonerado !== undefined ? icmsDesonerado.toLocaleString("pt-BR", {style: "currency", currency: "BRL"}) : ''}
                       tabIndex="9"
                       decimalSeparator=","
                       thousandSeparator="."
                       decimalScale={2}
                       onValueChange={(values, sourceInfo) => {
                       setIcmsDesonerado(values.floatValue);
                       }}  
             
                          />
                     </div>

                     <div className="col-3">
                     <label htmlFor="inputAddress" className="form-label">BC ICMS RET</label>   
                     <NumericFormat 
                       className="form-control"
                       id="inputVenda"
                       onBlur={calculaImposto} 
                       defaultValue={BcIcmsRet}
                       value={BcIcmsRet !== undefined ? BcIcmsRet.toLocaleString("pt-BR", {style: "currency", currency: "BRL"}) : ''}
                       tabIndex="9"
                       decimalSeparator=","
                       thousandSeparator="."
                       decimalScale={2}
                       onValueChange={(values, sourceInfo) => {
                       setBcIcmsRet(values.floatValue);
                       }}  
             
                          />
                     </div>

                     <div className="col-3">
                     <label htmlFor="inputAddress" className="form-label">ICMS ST RET</label>   
                     <NumericFormat 
                       className="form-control"
                       id="inputVenda"
                       onBlur={calculaImposto} 
                       defaultValue={IcmsStRet}
                       value={IcmsStRet !== undefined ? IcmsStRet.toLocaleString("pt-BR", {style: "currency", currency: "BRL"}) : ''}
                       tabIndex="9"
                       decimalSeparator=","
                       thousandSeparator="."
                       decimalScale={2}
                       onValueChange={(values, sourceInfo) => {
                       setIcmsStRet(values.floatValue);
                       }}  
             
                          />
                     </div>
                  </div>

                  <hr />
                  <div className='row'>
                  <div className="col-12" ref={refLinha2} style={{ display: 'none', flexWrap: 'wrap', marginLeft: -10, marginRight: -10 }} id="linhaOculta">
                     <div className="col-3">
                     <label htmlFor="inputAddress" className="form-label">M.V.A ICMS SUBST</label>   
                     <NumericFormat 
                       className="form-control"
                       id="inputVenda"
                       onBlur={calculaImposto} 
                       defaultValue={MvaIcms}
                       value={MvaIcms !== undefined ? MvaIcms.toLocaleString("pt-BR", {style: "currency", currency: "BRL"}) : ''}
                       tabIndex="9"
                       decimalSeparator=","
                       thousandSeparator="."
                       decimalScale={2}
                       onValueChange={(values, sourceInfo) => {
                       setMvaIcms(values.floatValue);
                       }}  
             
                          />
                     </div>

                     <div className="col-3">
                     <label htmlFor="inputAddress" className="form-label">RED. BASE CALC ST</label>   
                     <NumericFormat 
                       className="form-control"
                       id="inputVenda"
                       onBlur={calculaImposto} 
                       defaultValue={redBaseCalcSt}
                       value={redBaseCalcSt !== undefined ? redBaseCalcSt.toLocaleString("pt-BR", {style: "currency", currency: "BRL"}) : ''}
                       tabIndex="9"
                       decimalSeparator=","
                       thousandSeparator="."
                       decimalScale={2}
                       onValueChange={(values, sourceInfo) => {
                       setRedBaseCalcSt(values.floatValue);
                       }}  
             
                          />
                     </div>

                     <div className="col-2">
                     <label htmlFor="inputAddress" className="form-label">BASE ICMS SUBST</label>   
                     <NumericFormat 
                       className="form-control"
                       id="inputVenda"
                       onBlur={calculaImposto} 
                       defaultValue={baseIcmsSubst}
                       value={baseIcmsSubst !== undefined ? baseIcmsSubst.toLocaleString("pt-BR", {style: "currency", currency: "BRL"}) : ''}
                       tabIndex="9"
                       decimalSeparator=","
                       thousandSeparator="."
                       decimalScale={2}
                       onValueChange={(values, sourceInfo) => {
                       setBaseIcmsSubst(values.floatValue);
                       }}  
             
                          />
                     </div>

                     <div className="col-2">
                     <label htmlFor="inputAddress" className="form-label">ALIQUOTA ICMS SUBST (%)</label>   
                     <NumericFormat 
                       className="form-control"
                       id="inputVenda"
                       onBlur={calculaImposto} 
                       defaultValue={aliquotaIcmsSubst}
                       value={aliquotaIcmsSubst !== undefined ? aliquotaIcmsSubst.toLocaleString("pt-BR", {style: "currency", currency: "BRL"}) : ''}
                       tabIndex="9"
                       decimalSeparator=","
                       thousandSeparator="."
                       decimalScale={2}
                       onValueChange={(values, sourceInfo) => {
                       setAliquotaIcmsSubst(values.floatValue);
                       }}  
             
                          />
                     </div>

                     <div className="col-2">
                     <label htmlFor="inputAddress" className="form-label">VALOR ICMS SUBST</label>   
                     <NumericFormat 
                       className="form-control"
                       id="inputVenda"
                       onBlur={calculaImposto} 
                       defaultValue={valorIcmsSubst}
                       value={valorIcmsSubst !== undefined ? valorIcmsSubst.toLocaleString("pt-BR", {style: "currency", currency: "BRL"}) : ''}
                       tabIndex="9"
                       decimalSeparator=","
                       thousandSeparator="."
                       decimalScale={2}
                       onValueChange={(values, sourceInfo) => {
                       setValorIcmsSubst(values.floatValue);
                       }}  
             
                          />
                     </div>
                     </div>
                  </div>
                  <hr ref={hrRef2} style={{ display: 'none' }}/>

                  <div className='row'>
                  <div className="col-3">
                     <label htmlFor="inputAddress" className="form-label">PIS/CST - SITUAÇÃO TRIBUTÁRIA</label>   
                     <input type="text" value={pisCst} onBlur={calculaImposto} onChange={(e) => setPisCst(e.target.value)}className="form-control" id="inputCodigo" tabIndex="4" required autoComplete="off" />
                     </div>

                     <div className="col-3">
                     <label htmlFor="inputAddress" className="form-label">BC PIS</label>   
                     <NumericFormat 
                       className="form-control"
                       id="inputVenda"
                       onBlur={calculaImposto} 
                       defaultValue={bcPis}
                       value={bcPis !== undefined ? bcPis.toLocaleString("pt-BR", {style: "currency", currency: "BRL"}) : ''}
                       tabIndex="9"
                       decimalSeparator=","
                       thousandSeparator="."
                       decimalScale={2}
                       onValueChange={(values, sourceInfo) => {
                       setBcPis(values.floatValue);
                       }}  
             
                          />
                     </div>

                     <div className="col-3">
                     <label htmlFor="inputAddress" className="form-label">ALIQUOTA PIS (%)</label>   
                     <NumericFormat 
                       className="form-control"
                       id="inputVenda"
                       onBlur={calculaImposto} 
                       defaultValue={aliquotaPis}
                       value={aliquotaPis !== undefined ? aliquotaPis.toLocaleString("pt-BR", {style: "currency", currency: "BRL"}) : ''}
                       tabIndex="9"
                       decimalSeparator=","
                       thousandSeparator="."
                       decimalScale={2}
                       onValueChange={(values, sourceInfo) => {
                       setAliquotaPis(values.floatValue);
                       }}  
             
                          />
                     </div>

                     <div className="col-3">
                     <label htmlFor="inputAddress" className="form-label">VALOR PIS</label>   
                     <NumericFormat 
                       className="form-control"
                       id="inputVenda"
                       onBlur={calculaImposto} 
                       defaultValue={valorPis}
                       value={valorPis !== undefined ? valorPis.toLocaleString("pt-BR", {style: "currency", currency: "BRL"}) : ''}
                       tabIndex="9"
                       decimalSeparator=","
                       thousandSeparator="."
                       decimalScale={2}
                       onValueChange={(values, sourceInfo) => {
                       setValorPis(values.floatValue);
                       }}  
             
                          />
                     </div>
                  </div>
                  
           
                <hr />

                <div className='row'>
                  <div className="col-3">
                     <label htmlFor="inputAddress" className="form-label">CONFINS/CST - SITUAÇÃO TRIBUTÁRIA</label>   
                     <input type="text" value={confinsCst} onBlur={calculaImposto} onChange={(e) => setConfinsCst(e.target.value)}className="form-control" id="inputCodigo" tabIndex="4" required autoComplete="off" />
                     </div>

                     <div className="col-3">
                     <label htmlFor="inputAddress" className="form-label">BC CONFINS</label>   
                     <NumericFormat 
                       className="form-control"
                       id="inputVenda"
                       onBlur={calculaImposto} 
                       defaultValue={bcConfins}
                       value={bcConfins !== undefined ? bcConfins.toLocaleString("pt-BR", {style: "currency", currency: "BRL"}) : ''}
                       tabIndex="9"
                       decimalSeparator=","
                       thousandSeparator="."
                       decimalScale={2}
                       onValueChange={(values, sourceInfo) => {
                       setBcConfins(values.floatValue);
                       }}  
             
                          />
                     </div>

                     <div className="col-3">
                     <label htmlFor="inputAddress" className="form-label">ALIQUOTA CONFINS (%)</label>   
                     <NumericFormat 
                       className="form-control"
                       id="inputVenda"
                       onBlur={calculaImposto} 
                       defaultValue={aliquotaConfis}
                       value={aliquotaConfis !== undefined ? aliquotaConfis.toLocaleString("pt-BR", {style: "currency", currency: "BRL"}) : ''}
                       tabIndex="9"
                       decimalSeparator=","
                       thousandSeparator="."
                       decimalScale={2}
                       onValueChange={(values, sourceInfo) => {
                       setAliquotaConfins(values.floatValue);
                       }}  
             
                          />
                     </div>

                     <div className="col-3">
                     <label htmlFor="inputAddress" className="form-label">VALOR CONFINS</label>   
                     <NumericFormat 
                       className="form-control"
                       id="inputVenda"
                       onBlur={calculaImposto} 
                       defaultValue={valorConfins}
                       value={valorConfins !== undefined ? valorConfins.toLocaleString("pt-BR", {style: "currency", currency: "BRL"}) : ''}
                       tabIndex="9"
                       decimalSeparator=","
                       thousandSeparator="."
                       decimalScale={2}
                       onValueChange={(values, sourceInfo) => {
                       setValorConfins(values.floatValue);
                       }}  
             
                          />
                     </div>
                  </div>
                  
           
                <hr />
            
                      
            
            <div className="footer d-flex justify-content-between">
              <button className='btn btn-danger botao-fechar' onClick={props.onRequestClose}>Cancelar</button>
              <button className='btn btn-primary ml-2' onClick={gravaItem}>Adicionar</button>
            </div>
          </div>
    

          {
           erroValidacao ? 
           <SweetAlert
              danger
              confirmBtnText="OK"
              confirmBtnBsStyle="danger"
              title="Erro"
              onConfirm={() => setErrovalidacao(false)}                            
            >
              {msgErro}
            </SweetAlert>:null
           
          }

    
  </Modal>
}

export default ModalAdicaoItem;