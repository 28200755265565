import React from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import JsBarcode from 'jsbarcode';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

class EtiquetaRoupaPDF extends React.Component {
  generatePDF = () => {
    const { produto } = this.props;
    const barcodeOptions = {
      format: 'CODE128',
      displayValue: false,
      fontSize: 10,
      lineColor: '#000',
      width: 1,
      height: 50 
    };

    function abreviarString(str, maxLength) {
      if (str.length > maxLength) {
        return str.slice(0, maxLength - 1) + '.';
      }
      return str;
    }

    const descricao = abreviarString(produto.DESCRICAO, 19);

    const canvas = document.createElement('canvas');
    JsBarcode(canvas, produto.COD_PRODUTO, barcodeOptions);
    const barcodeDataURL = canvas.toDataURL('image/png');

    const documentDefinition = {
      content: [
        {
          columns: [
            {
              stack: [
                {
                  image: barcodeDataURL,
                  width: 90,
                  height: 50, // Ajuste da altura do código de barras
                  margin: [-35, -30, 30, 0]
                },
                { 
                  text: produto.COD_PRODUTO, 
                  style: 'codigo', 
                  fontSize: 10, 
                  alignment: 'center', 
                  margin: [-495, 0, 0, 0] // Código embaixo do código de barras
                }
              ]
            }
          ]
        },
        {
          columns: [
            { text: 'Data: __/__/____', margin: [-30, 10, 30, 0], fontSize: 10 }
          ]
        },
        {
          columns: [
            { 
              text: ` ${descricao}`, 
              style: 'item', 
              width: 170, 
              fontSize: 9, 
              margin: [-30, 10, 30, 0] 
            }
          ]
        },
        {
          columns: [
            { 
              text: `${produto.VENDA.toLocaleString('pt-br', { style: 'currency', currency: 'BRL'})}`, 
              style: 'item', 
              fontSize: 12, 
              margin: [-30, 10, 30, 0]  
            }
          ]
        }
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          alignment: 'center',
          margin: [0, 0, 0, 20]
        },
        item: {
          margin: [0, 5],
          bold: true,
          alignment: 'left'
        },
        codigo: {
          bold: true,
          alignment: 'center'
        }
      }
    };

    pdfMake.createPdf(documentDefinition).open();
  };

  render() {
    return (
      <div>
        <button onClick={this.generatePDF} className='dropdown-item'>
          <i className="fas fa-barcode etiquetaIcon"></i> Roupa
        </button>
      </div>
    );
  }
}

export default EtiquetaRoupaPDF;