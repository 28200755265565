import React from 'react';
import { Link } from 'react-router-dom';
import EtiquetaPDF from './EtiquetaPDF';
import EtiquetaTriPDF from './EtiquetaTri';
import Modal from 'react-modal';
import { useState } from 'react';
import EtiquetaRoupaPDF from './etiquetaRoupa';



function ProdutoTable({ produtos, confirmDeleteUser }) {
  return (
    

    <table translate='no' id='table-prod' className="table table-hover table-striped">
      <thead>
        <tr>
          <th scope="col">Codigo</th>
          <th scope="col">Descricao</th>
          <th scope="col">Unidade</th>
          <th scope="col">Estoque</th>
          <th scope="col">St</th>
          <th scope="col">Ncm</th>
          <th scope="col">Compra</th>
          <th scope="col">Venda</th>
          <th scope="col">Ações</th>
          <th scope='col'>Etiquetas</th>
        </tr>
      </thead>
      <tbody className="table-group-divider">
        {produtos.map((produto) => (
          <tr key={produto.COD_PRODUTO}>
            <td>{produto.COD_PRODUTO}</td>
            <td>{produto.DESCRICAO}</td>
            <td>{produto.UNIDADE}</td>
            <td>{Number(produto.ESTOQUE).toFixed(3)}</td>
            <td>{produto.ST.substring(1)}</td>
            <td>{produto.COD_NCM}</td>
            <td>{produto.COMPRA.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
            <td>{produto.VENDA.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
            <td>
            <div id="table-prod" className="dropdown">
  <button id='table-prod' className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
    Ações
  </button>
  <ul className="dropdown-menu">
    <li>
      <Link to={`/app/novoProduto/${produto.ID}/0`} className="dropdown-item">
        <i className="bi bi-eye-fill"></i> Visualizar
      </Link>
    </li>
    <li>
      <Link to={`/app/novoProduto/${produto.ID}/2`} className="dropdown-item">
        <i className="bi bi-pen-fill"></i> Editar
      </Link>
    </li>
    <li>
      <button onClick={() => confirmDeleteUser(produto.ID)} className="dropdown-item">
        <i className="bi bi-trash-fill"></i> Excluir
      </button>
    </li>

  </ul>
</div>
            
            </td>
            <td>
            <div id="table-prod" className="dropdown">
            <button id='table-prod' className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
               Etiquetas
            </button>
            <ul className="dropdown-menu">
            <li className="dropdown-item">
          <EtiquetaRoupaPDF produto={produto} />
        </li>
        <li className="dropdown-item">
          <EtiquetaPDF produto={produto} />
        </li>
    <li className="dropdown-item">
          <EtiquetaTriPDF produto={produto} />
        </li>
    </ul>
            </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default ProdutoTable;
