import React from 'react';
import print from '../../../images/mokup.png'
import '../../../index.css'
import './Banners.css'

function Banner(){
    return <section id='banner'>
        <div className='container'>
       
                  <div className='row'>
                  <div className='col-lg-6'>
                   <h1>SRI web</h1>
                   <h4>O SRI-web oferece desde a organização e centralização de informações, análise de dados em tempo real, essa solução é essencial para melhorar a eficiência operacional, aumentar a produtividade e impulsionar o crescimento sustentável das empresas.</h4>
                   </div>

                  <div className='col-lg-6'>
                   <img src={print} alt="" />
                  </div>

                  </div>      

                           
               
            
      </div>  

    </section>
}



export default Banner;