import React,{useState} from "react";
import Navbar from "../../navbar/navbar";
import { api } from "../../../services/api";
import { Link,Navigate } from 'react-router-dom'
import './colaborador.css'
import { vhostV1 } from "../../funcoes";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import FooterBar from "../../footer/footerBar";




function NovoColaborador(){

    const inputs = document.querySelectorAll("input, select");

    // Adicione um manipulador de eventos para o evento "keydown" em cada entrada ou select
    inputs.forEach(input => {
      input.addEventListener("keydown", e => {
        // Se a tecla pressionada for Enter
        if (e.key === "Enter") {
          e.preventDefault(); // evita que a tecla "Enter" envie um formulário, se aplicável

          // Obtenha o índice do elemento atual
          const currentIndex = Array.from(inputs).indexOf(e.target);
  
          // Mova o foco para o próximo elemento de entrada ou select
          const nextIndex = currentIndex + 1;
          if (nextIndex < inputs.length) {
            inputs[nextIndex].focus();
          }
        }
      });
    });
 

   const [nome,setNome] = useState('');
   const [login,setLogin] = useState('');
   const [senha,setSenha] = useState('');
   const [ncm,setNcm] = useState('');
   const [sucesso,setSucesso] = useState('N')
   const [mensagem, setMensagem] = useState('');
   const [showPassword, setShowPassword] = useState(false);
   
   let config = {
    headers: {
      'Authorization': localStorage.getItem("token")
    }
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
};
       


  function salvaColaborador(e){
    e.preventDefault();
        api.post(vhostV1('/webcash/usuario/incluir'),{
            loja: localStorage.getItem('loja'),
            EMPRESA:0,
            NOME:nome.toUpperCase(),
            LOGIN:login.toUpperCase(),
            SENHA:senha,
            },config)
        .then(function(response){
            setMensagem('');
            setSucesso('S');
            console.log()
        }).catch((erro) =>{
            setMensagem(erro);
            setSucesso('N');
        });
     }


     return <div className="conteiner-fluid titulo ">
         <Navbar/>
         <div className="offset-lg-1 col-lg-10">
               <h2>Cadastro de Colaborador</h2>      
                
                <div className="conteiner-fluid">
                <form  className="row tela">
                   
                   <div className="col-4">
                       <label htmlFor="inputAddress" className="form-label">NOME</label>
                       <input  type="text" onChange={(e)=> setNome(e.target.value)} className="form-control" id="inputAddress" />
                   </div>
                   <div className="col-4">
                       <label htmlFor="inputAddress" className="form-label">LOGIN</label>
                       <input  type="text" onChange={(e)=> setLogin(e.target.value)} className="form-control" id="inputAddress" />
                   </div>
                   
                                     
       
                   <div className="col-4">
            <label htmlFor="inputAddress" className="form-label">SENHA</label>
            <div className="input-group">
                <input
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e) => setSenha(e.target.value)}
                    className="form-control"
                    id="inputAddress"
                    value={senha}
                />
                <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={togglePasswordVisibility}
                >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
            </div>
        </div>
                   
                   
                   
                   <div className="col-12">
                       <Link to="/app/colaborador" type="submit" className="btn btn-outline-primary butao">Desistir</Link>
                       <button type="submit" onClick={salvaColaborador} className="btn btn-primary butao">Salvar</button>
                   </div>
       
                   {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
                   {sucesso === 'S' ? <Navigate to='/app/colaborador' /> : null}
                          
                   </form> 
            </div>
        </div>  
        <FooterBar/> 
   </div>

}



export default NovoColaborador;