import React from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import JsBarcode from 'jsbarcode';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

class EtiquetaTriPDF extends React.Component {
  
  generatePDF = () => {
    const { produto } = this.props;
    const barcodeOptions = {
      format: 'CODE128',
      displayValue: false, 
      fontSize: 10,
      lineColor: '#000',
      width: 1,
      height: 20
    };

    const canvas = document.createElement('canvas');
    JsBarcode(canvas, produto.COD_PRODUTO, barcodeOptions);
    const barcodeDataURL = canvas.toDataURL('image/png');

    const documentDefinition = {
      pageSize: {
        width: 311.8, // 11 cm em pontos (1 cm = 28.35 pt)
        height: 101.7 // 5 cm em pontos
      },
      pageMargins: [8, 8, 8, 8],
      content: [
        {
          table: {
            widths: ['*', '*', '*'],
            body: [
              [
                this.createEtiqueta(produto.DESCRICAO, produto.COD_PRODUTO, barcodeDataURL, 0),
                this.createEtiqueta(produto.DESCRICAO, produto.COD_PRODUTO, barcodeDataURL, 6),
                this.createEtiqueta(produto.DESCRICAO, produto.COD_PRODUTO, barcodeDataURL, 10)
              ]
            ]
          },
          layout: {
            hLineColor: () => '#fff',
            vLineColor: () => '#fff',
            paddingLeft: () => 0,
            paddingRight: () => 0,
            paddingTop: () => 0,
            paddingBottom: () => 0
          }
        }
      ]
    };

    pdfMake.createPdf(documentDefinition).open();
  };

  createEtiqueta(descricao, codProduto, barcodeDataURL, margemEsquerda) {
    return {
      margin: [0, 0, 0, 0],
      stack: [
        { 
          text: descricao,
          style: 'item', 
          fontSize: 6, 
          alignment: 'center', 
          margin: [0, 2],
          width: 94.5 // Adicionando largura para que a quebra funcione
        },
        {
          image: barcodeDataURL,
          width: 80,
          height: 25,
          alignment: 'center',
          margin: [0,-2]
          
        },
        { 
          text: codProduto, 
          style: 'item', 
          fontSize: 8, 
          alignment: 'center', 
          margin: [0, -2] 
        }
      ],
      width: 94.5, 
      height: 126.7,
      margin: [margemEsquerda, 0, 0, 0] 
    };
  }

  render() {
    return (
      <div>
        <button onClick={this.generatePDF} className='dropdown-item'>
          <i className="fas fa-barcode etiquetaIcon"></i>TAG
        </button>
      </div>
    );
  }
}

export default EtiquetaTriPDF;

