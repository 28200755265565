import React, { useEffect, useState } from 'react'
import Modal from 'react-modal/lib/components/Modal';
import './ModalPesquisa.css'
import BuscaVazaia from '../buscavazia/buscaVazia';
import customizeScrollbar from 'customsb';


function ModalPesquisa(props) {

  useEffect(() => {
    // O uso do useEffect garante que a função seja chamada apenas no lado do cliente
    customizeScrollbar({
      scrollbarWidth: "10px",
      trackBackground: "#333",
      thumbBackground:
        "linear-gradient(178.8deg, #ffa066 47.41%, #ff9666 65.18%, #ff783a 90.84%)",
      thumbBorderRadius: "10px",
    });
  }, []);
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      overlayClassName="react-modal-overlay"
      ariaHideApp={false}
      className="react-modal-content"
    >
      <div className="container-fluid pesquisa-modal">
        <div className="row">
          <h4>{props.titulo}</h4>
          <hr />

          <div className="col-12">
            <div className="input-group mb-3">
              <input
                onChange={(e) => props.setBusca(e.target.value)}
                type="text"
                className="form-control"
                placeholder="Pesquisar por nome"
                aria-describedby="button-addon2"
              />
              <button
                onClick={props.listarPesquisa}
                className="btn btn-primary"
                type="button"
                id="button-addon2"
              >
                <i className="bi bi-search"></i> Pesquisar
              </button>
            </div>
          </div>
        </div>

        <div className='row produtos'>
        
          {props.itens.length > 0 ? (
           
              <table className="table table-hover table-striped">
                <thead>
                  <tr>
                    <th scope="col">Código</th>
                    <th scope="col">Razão</th>
                    <th scope="col">Ação</th>
                  </tr>
                </thead>
                <tbody className="table-group-divider">
                  {props.itens.map((pessoa) => {
                    return (
                      <tr key={pessoa.COD_CADASTRO}>
                        <td>{pessoa.CNPJ}</td>
                        <td>{pessoa.RAZAO}</td>
                        <td>
                          <button
                            className="btn btn-sm"
                            onClick={() => props.pesquisaPessoa(pessoa.COD_CADASTRO)}
                          >
                            <i className="bi bi-file-arrow-down-fill"></i>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            
          ) : (
            <BuscaVazaia />
          )}
       </div>
         
       <div className="row align-items-end footer">  
        <button className="btn btn-danger mt-3" onClick={props.onRequestClose}>
          Cancelar
        </button>
        </div>
      </div>
    </Modal>
  );
}



export default ModalPesquisa;


