import React,{useState} from "react";
import Navbar from "../../navbar/navbar";
import { api } from "../../../services/api";
import { Link,Navigate, useParams } from 'react-router-dom'
import { useEffect } from "react";
import { vhostV1 } from "../../funcoes";
import SweetAlert from "react-bootstrap-sweetalert";
import FooterBar from "../../footer/footerBar";


function NovaFinalizadora() {

    const {tag} =useParams();
    const {id} =useParams();


    const [descricaoV, setDescricao] =useState('');
    const [finalizadoraV, setFinalizadora] = useState([]);
   const [tefV, setTef] = useState('');
   const [conta_bancariaV, setConta_bancaria] = useState(0);
   const [prazoV, setPrazo] = useState(0);
   const [descontoV, setDesconto] = useState(0);
   const [nr_parcelasV, setNr_parcelas] = useState(0);
   const [tab_precoV,setTab_preco] = useState(0);
   const [jurosV, setJuros] = useState(0);
   const [vlr_minimo_opV, setValor_minimo_op] = useState(0);
   const [duplicataV, setDuplicata] = useState("F");
   const [cartaoV, setCartao] = useState(0);
   const [sinteticoV, setSintetico] = useState('');
   const [analiticoV, setAnalitico] = useState('');
   const [desc_impV, setDesc_imp] = useState('dinheiro');
   const [permit_trocoV, setPermit_troco] = useState('N');
   const [cod_cedenteV, setCod_cedente]= useState('');
   const [cadastrado_por,setCadastrado_Por] = useState(99);
   const [alterado_por,setAlterado_Por] = useState(99);
   const [mobile, setMobile] = useState(false);
   const [planoAnalitico, setPlanoAnalitico] = useState([]);
   const [planoSintetico, setPlanoSintetico] = useState([]);
   const [codFinalizadora, setCodFinalizadora] = useState(-1);

   const [sucesso,setSucesso] = useState('N')
   const [mensagem, setMensagem] = useState('');
   const [sResult, setSresult] = useState(0);
   const [erroValidacao, setErrovalidacao] = useState(false);
   const [msgErro, setmsgErro] = useState('');
   const [busca, setBusca] = useState('');

   var data = new Date();

   const dataAtual = data.toSRIFormatString();
  
   console.log('data formatada:', dataAtual); //2022

   let config = {
    headers: {
      'Authorization': localStorage.getItem("token")
    }
  }


  const inputs = document.querySelectorAll("input, select");
  // Adicione um manipulador de eventos para o evento "keydown" em cada entrada ou select
  inputs.forEach(input => {
    input.addEventListener("keydown", e => {
      // Se a tecla pressionada for Enter
      if (e.key === "Enter") {
        e.preventDefault(); // evita que a tecla "Enter" envie um formulário, se aplicável

        // Obtenha o índice do elemento atual
        const currentIndex = Array.from(inputs).indexOf(e.target);

        // Mova o foco para o próximo elemento de entrada ou select
        const nextIndex = currentIndex + 1;
        if (nextIndex < inputs.length) {
          inputs[nextIndex].focus();
        }
      }
    });
  }); 

  console.log('id: ' + id);
  

  useEffect(function(){

    api.get(vhostV1('webcash/finalizadora/RetornaFinalizadora/'+id),config)
    .then(function(response){
      if (response && response.data && response.data.dados){
        console.log(response.data.dados)
        setCodFinalizadora(response.data.dados.COD_FINALIZADORA);
        setFinalizadora(response.data.dados.COD_FINALIZADORA);
        setDescricao(response.data.dados.DESCRICAO);
        setTef(`${response.data.dados.TEF}`);
        setConta_bancaria(response.data.dados.CONTA_BANCARIA);
        setPrazo(response.data.dados.PRAZO);
        setDesconto(response.data.dados.DESCONTO);
        setNr_parcelas(response.data.dados.NR_PARCELAS);
        setTab_preco(response.data.dados.TAB_PRECO);
        setJuros(response.data.dados.JUROS);
        setValor_minimo_op(response.data.dados.VLR_MINIMO_OP);
        setDuplicata(response.data.dados.DUPLICATA);
        setCartao(response.data.dados.CARTAS);
        setSintetico(response.data.dados.SINTETICO);
        setAnalitico(response.data.dados.ANALITICO);
        setDesc_imp(response.data.dados.DESC_IMP);
        setPermit_troco(response.data.dados.PERMIT_TROCO);
        setCod_cedente(response.data.dados.COD_CEDENTE);
      }
    })
  },[])

  const json = {
    loja: localStorage.getItem('loja'),
    finalizadora:Number(codFinalizadora),
    descricao:descricaoV.toUpperCase(),
    tef:tefV,
    conta_bancaria:Number(conta_bancariaV),
    prazo:prazoV,
    desconto:descontoV,
    nr_parcelas:Number(nr_parcelasV),
    tab_preco:Number(tab_precoV),
    juros:jurosV,
    vlr_minimo_op:vlr_minimo_opV,
    duplicata:duplicataV,
    cartao:cartaoV,
    sintetico:Number(sinteticoV),
    analitico:Number(analiticoV),
    desc_imp:desc_impV,
    permit_troco:'N',
    cod_cedente:cod_cedenteV
}

console.log(json);


  


 
 
  function SalvaFinalizadora(){

    {if(descricaoV.length >30 )
      { setmsgErro("Descrição do banco maior que permitido");
       setErrovalidacao(true)}
     else if(desc_impV.length >50)
     { setmsgErro("desconto imp maior que permitido");
     setErrovalidacao(true)}
     else if(descricaoV.length === 0)
        { setmsgErro("descrição não pode ser vazio");
        setErrovalidacao(true)}
     else if(tefV.length === 0)
            { setmsgErro("Selecione um tipo de operadora de cartão!"); 
             setErrovalidacao(true)}  
     else if(sinteticoV.length === 0)
        { setmsgErro("Selecione um sintetico!"); 
         setErrovalidacao(true)} 
     else if(analiticoV.length === 0)
        { setmsgErro("Selecione um analitico!"); 
         setErrovalidacao(true)}  
        
    
     
      else {      
    
     // fim do valida tamanhos

         //   e.preventDefault(e);
      api.post(vhostV1('webcash/finalizadora/inserirFinalizadora'),{
                loja: localStorage.getItem('loja'),
                finalizadora:Number(codFinalizadora),
                descricao:descricaoV.toUpperCase(),
                tef:tefV,
                conta_bancaria:Number(conta_bancariaV),
                prazo:prazoV,
                desconto:descontoV,
                nr_parcelas:Number(nr_parcelasV),
                tab_preco:Number(tab_precoV),
                juros:jurosV,
                vlr_minimo_op:vlr_minimo_opV,
                duplicata:duplicataV,
                cartao:cartaoV,
                sintetico:Number(sinteticoV),
                analitico:Number(analiticoV),
                desc_imp:desc_impV,
                permit_troco:'N',
                cod_cedente:cod_cedenteV
      },config)

      .then(function(response){
          if(response.data.result === 1 ){
            
            
              setMensagem('');
              setSucesso('S');
              }else{
               setmsgErro(response)
              
              }
          }).catch((erro) =>{
              setMensagem(erro);
              setSucesso('N');
              console.log('ERRO');
          });
      }}
      };

      function buscaAnalitico(e){
    
        api.post(vhostV1('webcash/planoAnalitico'), {
            loja: localStorage.getItem('loja'),
            empresa: 0,
            sintetico:e
          },config)
          .then(function (response) {
           
            
            setPlanoAnalitico(response.data.dados);  
            setSintetico(e);
                                 
          })
          .catch(function (error) {       
            console.log(error);
          });
        }


     
        
        useEffect(function(){
            api.post(vhostV1('webcash/planoSintetico'), {
                loja: 11,
                empresa: 0  
              },config)
              .then(function (response) {
                
                
                 setPlanoSintetico(response.data.dados);           
                }                               
              )
              .catch(function (error) {
                console.log(error);
                        
              });
         },[])

      return <div className="container-fluid titulo">
      <Navbar />
      <div className="offset-lg-3 col-lg-6">
          <h2>Cadastro de Finalizadora</h2>
          <hr />
  
          <form className="row tela">
              <div className="col-7 mb-3">
                  <label htmlFor="inputDescricao" className="form-label">Descrição</label>
                  <input type="text" onChange={(e) => setDescricao(e.target.value)} value={descricaoV} className="form-control" id="inputDescricao" />
              </div>

              {/* <div className="col-2 mb-3">
                  <label htmlFor="inputDesconto" className="form-label">Desconto</label>
                  <input type="text" onChange={(e) => setDesconto(e.target.value)} value={descontoV} className="form-control" id="inputDesconto" />
              </div> */}
  
              <div className="col-5 mb-3">
                  <label htmlFor="inputParcelas" className="form-label">Nº de Parcelas</label>
                  <input type="text" onChange={(e) => setNr_parcelas(e.target.value)} value={nr_parcelasV} className="form-control" id="inputParcelas" />
              </div>
  
              <div className="col-md-6 mb-3">
                  <label htmlFor="inputTef" className="form-label">Operadora de Cartão?</label>
                  <select value={tefV} id="inputTef" onChange={(e) => setTef(e.target.value)} className="form-select">
                      <option value={'99'}>Selecione uma opção</option>
                      <option value={'0'}>000 - Não Possui TEF</option>
                      <option value={'1'}>001 - Tef Discado/PayGo/Autta</option>
                      <option value={'2'}>002 - POS sem integração</option>
                      <option value={'3'}>003 - Reservado</option>
                      <option value={'4'}>004 - SITEF</option>
                      <option value={'5'}>005 - GiftCard</option>
                      <option value={'6'}>006 - POS(Moderninhas PRO)</option>
                      <option value={'7'}>007 - Reservado</option>
                      <option value={'8'}>008 - Reservado</option>
                      <option value={'9'}>009 - Crediário</option>
                      <option value={'10'}>010 - Vale Troca/Presente</option>
                      <option value={'11'}>011 - Documento Interno</option>
                      <option value={'14'}>014 - Smart POS</option>
                  </select>
              </div>
  
              <div className="col-4 mb-3">
                  <label htmlFor="inputContaBancaria" className="form-label">Conta Bancária</label>
                  <input type="text" onChange={(e) => setConta_bancaria(e.target.value)} value={conta_bancariaV} className="form-control" id="inputContaBancaria" />
              </div>
  
              <div className="col-2 mb-3">
                  <label htmlFor="inputPrazo" className="form-label">Prazo</label>
                  <input type="text" onChange={(e) => setPrazo(e.target.value)} value={prazoV} className="form-control" id="inputPrazo" />
              </div>
  
             
  
              <h5 className="mt-4">Dados Complementares</h5>
              <hr />
  
              <div className="col-3 mb-3">
                  <label htmlFor="inputTabPreco" className="form-label">Tabela Preço</label>
                  <input type="text" onChange={(e) => setTab_preco(e.target.value)} value={tab_precoV} className="form-control" id="inputTabPreco" />
              </div>
              
  
              <div className="col-3 mb-3">
                  <label htmlFor="inputCodCedente" className="form-label">Cod. Cedente</label>
                  <input type="text" onChange={(e) => setCod_cedente(e.target.value)} value={cod_cedenteV} className="form-control" id="inputCodCedente" />
              </div>
             
              <div className="col-3 mb-3">
                  <label htmlFor="inputValorMinimo" className="form-label">Valor Mínimo</label>
                  <input type="text" onChange={(e) => setValor_minimo_op(e.target.value)} value={vlr_minimo_opV} className="form-control" id="inputValorMinimo" />
              </div>

              <div className="col-3 mb-3">
                  <label htmlFor="inputDuplicata" className="form-label">Duplicata</label>
                  <input type="text" onChange={(e) => setDuplicata(e.target.value)} value={duplicataV} className="form-control" id="inputDuplicata" />
              </div>

              <div className="col-6 mb-3">
                  <label htmlFor="inputSintetico" className="form-label">Sintético</label>
                  <select id="inputSintetico" value={sinteticoV} onChange={(e) => buscaAnalitico(e.target.value) && setSintetico(e.target.value)} className="form-select">
                          <option value={'0'}>Selecione sintético</option>
                      {planoSintetico.map((psintetico) => (
                          <option key={psintetico.SINTETICO} value={psintetico.SINTETICO}>
                              {psintetico.DESCRICAO}
                          </option>
                      ))}
                  </select>
              </div>
  
              <div className="col-6 mb-3">
                  <label htmlFor="inputAnalitico" className="form-label">Analítico</label>
                  <select id="inputAnalitico" value={analiticoV} onChange={(e) => setAnalitico(e.target.value)} className="form-select">
                  <option value={99}>Selecione analítico</option>
                      {planoAnalitico.length > 0 ? (
                          planoAnalitico.map((panalitico) => (
                              <option key={panalitico.ANALITICO} value={panalitico.ANALITICO}>
                                  {panalitico.DESCRICAO}
                              </option>
                          ))
                      ) : (
                          <option value={0}>Selecione um sintetico para exibir as opções </option>
                      )}
                  </select>
              </div>
  
              
  
              
  
              
{/*   
              <h6 className="mt-4">Juros</h6>
              <div className="col-6 mb-3">
                  <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="jurosOptions" id="jurosNenhum" value="nenhum" />
                      <label className="form-check-label" htmlFor="jurosNenhum">Nenhum</label>
                  </div>
                  <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="jurosOptions" id="jurosValor" value="valor" />
                      <label className="form-check-label" htmlFor="jurosValor">Valor</label>
                  </div>
                  <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="jurosOptions" id="jurosPercentual" value="percentual" />
                      <label className="form-check-label" htmlFor="jurosPercentual">Percentual</label>
                  </div>
              </div> */}
              {/* <div className="col-2 mb-3">
                  <label htmlFor="inputJuros" className="form-label">Juros</label>
                  <input type="text" onChange={(e) => setJuros(e.target.value)} value={jurosV} className="form-control" id="inputJuros" />
              </div> */}
  
              <div className="col-12 mb-3">
                  <Link to="/app/finalizadora" className="btn btn-danger">Desistir</Link>
                  {tag !== '0' && (
                      <button type="button" onClick={SalvaFinalizadora} className="btn btn-primary ms-2">Salvar</button>
                  )}
              </div>
  
              {mensagem.length > 0 && <div className="alert alert-danger mt-2">{mensagem}</div>}
              {sucesso === 'S' && <Navigate to='/app/finalizadora' />}
  
              {erroValidacao && (
                  <SweetAlert
                      danger
                      confirmBtnText="OK"
                      confirmBtnBsStyle="danger"
                      title="Erro"
                      onConfirm={() => setErrovalidacao(false)}
                  >
                      {msgErro}
                  </SweetAlert>
              )}
          </form>
      </div>
      <FooterBar/> 
  </div>
  

}

export default NovaFinalizadora;