import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

function VendasProdutoPDF(pedidos) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    pedidos = Array.isArray(pedidos) ? pedidos : [];

    const reportTitle = [
        {
            text: 'Relatório de Produtos Vendidos',
            fontSize: 18,
            alignment: 'center',
            bold: true,
            margin: [20, 20, 0, 45] // left, top, right, bottom
        }
    ];

    const totalQuantidade = pedidos.reduce((acc, item) => acc + (parseInt(item.qtd, 10) || 0), 0);
    const totalValor = pedidos.reduce((acc, item) => acc + (parseFloat(item.vtotal) || 0), 0);
    const totalDesconto = pedidos.reduce((acc, item) => acc + (parseFloat(item.tdesconto) || 0), 0);

    const rankingProdutoBodyWithTotal = pedidos.length ?
        [...pedidos.map(item => [
            item.cod_produto || 'N/A',
            item.descricao || 'N/A',
            item.unidade || 'N/A',
            {text: item.qtd ? item.qtd.toFixed(3) : 'N/A',alignment: 'right'} ,
            {text: item.tdesconto ? item.tdesconto.toFixed(2) : '0.00', alignment: 'right'},
            {text: item.vtotal ? item.vtotal.toFixed(2) : '0.00', alignment: 'center'}
            
            
        ]),
        ['Total', '', '', {text: totalQuantidade.toFixed(3).toString(), alignment: 'right'}, {text: totalDesconto.toFixed(2), alignment: 'right'}, {text: totalValor.toFixed(2), alignment: 'center'}]] :
        [['Nenhum produto encontrado', '', '', '', '', '']];

    const details = [
        {
            table: {
                headerRows: 1,
                body: [
                    [
                        { text: 'Código', style: 'tableHeader', fontSize: 12 },
                        { text: 'Descrição', style: 'tableHeader', fontSize: 12 },
                        { text: 'Unidade', style: 'tableHeader', fontSize: 12 },
                        { text: 'Quantidade', style: 'tableHeader', fontSize: 12},
                        { text: 'Desconto', style: 'tableHeader', fontSize: 12},
                        { text: 'Valor Total', style: 'tableHeader', fontSize: 12 }
                    ],
                    ...rankingProdutoBodyWithTotal,
                ],
                widths: [60, 150, 50, 80, 70, 100], // Ajuste as larguras das colunas conforme necessário
            },
            layout: 'lightHorizontalLines'
        }
    ];

    function Rodape(currentPage, pageCount) {
        return [
            {
                text: `Página ${currentPage} de ${pageCount}`,
                alignment: 'right',
                fontSize: 9,
                margin: [0, 10, 20, 0] // left, top, right, bottom
            }
        ];
    }

    const docDefinitions = {
        pageSize: 'A4',
        pageMargins: [15, 50, 15, 40],

        header: [reportTitle],
        content: [details],
        footer: Rodape
    };

    pdfMake.createPdf(docDefinitions).open();
}

export default VendasProdutoPDF;

