import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from 'pdfmake/build/vfs_fonts'

function finalizadoraPDF(finalizadora){
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const reportTitle = [
        {
            text: "Finalizadoras",
            fontSize: 15,
            bold: true,
            margin: [15, 20, 0, 45]
        }
    ];

    const dados = finalizadora.map((finalizadora) =>{
        return [
            {text: finalizadora.COD_FINALIZADORA, fontSize: 9, margin: [0, 2, 0, 2]},
            {text: finalizadora.DESCRICAO, fontSize: 9, margin: [0, 2, 0, 2]},
            {text: finalizadora.TEF, fontSize: 9, margin: [0, 2, 0, 2]},
            {text: finalizadora.PRAZO, fontSize: 9, margin: [0, 2, 0, 2]},
            {text: finalizadora.DESCONTO, fontSize: 9, margin: [0, 2, 0, 2]}
        ]
    });

    const details = [
        {
            table:{
                headerRows: 1,
                widths: ['*', '*', '*', '*', '*', '*'],
                body: [
                    [
                        {text: 'Cod. Finalizadora', style: 'tableHeader', fontSize: 10},
                        {text: 'Descrição', style: 'tableHeader', fontSize: 10},
                        {text: 'TEF', style: 'tableHeader', fontSize: 10},  
                        {text: 'Prazo', style: 'tableHeader', fontSize: 10},
                        {text: 'Desconto', style: 'tableHeader', fontSize: 10}
                    
                    ],
                    ...dados
                ]
            },
            layout: 'headerLineOnly'
        }
    ];

    function Rodape(currentPage, pageCount){
        return [
            {
                text: currentPage + ' / ' + pageCount,
                alignment: 'right',
                fontSize: 9,
                margin: [0, 10, 20, 0]
            }
        ]
    }

    const docDefinitions = {
        pageSize: "A4",
        pageMargins: [15, 50, 15, 40],

        header: [reportTitle],
        content: [details],
        footer: Rodape
    }

    pdfMake.createPdf(docDefinitions).open();

}

export default finalizadoraPDF;