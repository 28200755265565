import React from 'react';
import Modal from 'react-modal/lib/components/Modal';
import { useState } from 'react';
import { vhostV1 } from '../funcoes';
import api from '../../services/api';
import { NumericFormat } from 'react-number-format';
import ModalProduto from '../components/modalProduto/ModalProduto';
import './EntradaEstoque.css';






function ModalAdicaoEstoque(props){
  const inputs = document.querySelectorAll("input, select");
    // Adicione um manipulador de eventos para o evento "keydown" em cada entrada ou select
    inputs.forEach(input => {
      input.addEventListener("keydown", e => {
        // Se a tecla pressionada for Enter
        if (e.key === "Enter") {
          e.preventDefault(); // evita que a tecla "Enter" envie um formulário, se aplicável

          // Obtenha o índice do elemento atual
          const currentIndex = Array.from(inputs).indexOf(e.target);
  
          // Mova o foco para o próximo elemento de entrada ou select
          const nextIndex = currentIndex + 1;
          if (nextIndex < inputs.length) {
            inputs[nextIndex].focus();
          }
        }
      });
    }); 

    console.log(JSON.stringify(props));
    


  let config = {
    headers: {
      'Authorization': localStorage.getItem("token")
    }
  }

  const [codProduto,setCodProduto] = useState('');
  const [descricao,setDescricao] = useState('')
  const [venda,setVenda] = useState(0);
  const [vendaDefault,setVendaDefault] = useState(0);
  const [qtd,setQtd] = useState();
  const [desconto,setDesconto] = useState(0);
  const [estoqueAtual,setEstoqueAtual] = useState(0);
  const [total,setTotal] = useState(0);
  const [ncm, setNcm] = useState(0);

  


  function calculaPreco(){

    setTotal(qtd * vendaDefault - desconto);
 }

  
  async function AdicionarItem(){
        
    await api.post(vhostV1('webcash/produto/entradaestoqueitem'), {
      cod_entrada: props.idVenda,
      codProduto: codProduto,
      qtd: qtd,
      vendedor: 0
     },config)
     .then(function (response) {       
        setDescricao('');
        setQtd('');
        setTotal('');
        setVenda('');  
        props.onRequestClose();                          
                
     })
     .catch(function (error) {
      
       console.log(error);

               
     });


 }


    
    function openModalProduto(){
        setIsProdutoOpen(true)
    }

    function closeModalProduto(){
        setIsProdutoOpen(false)
    }        
        
 // funcao para buscar produto
 function buscaIten(){
  api.post(vhostV1('webcash/produto/retornaproduto/'+codProduto), {
    loja: localStorage.getItem('loja'),
    empresa: 0         
  },config)
  .then(function (response) { 
    
   if(response && response.data && response.data.dados){
    setDescricao(response.data.dados.DESCRICAO);
    setEstoqueAtual(Number(response.data.dados.ESTOQUE));
     }
   else{
    alert("Codigo não encontrado");
   }  
    
   })
  .catch(function (error) {
   
    console.log(error);
            
  });
   };

   const [isProdutoOpen,setIsProdutoOpen] = useState(false);
   

   function openModalProduto(){
      setIsProdutoOpen(true)
   }

   function closeModalProduto(){
      
      setIsProdutoOpen(false)
   }

   return <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      overlayClassName="react-modal-overlay"
      ariaHideApp={false}
      className="adicao-modal-content" >
      <ModalProduto
        isOpen={isProdutoOpen}
        onRequestClose={closeModalProduto}
        setCodProduto={setCodProduto}
        setDescricao={setDescricao}
        setVendaDefault={setVendaDefault}
        setNcm={setNcm}
        titulo="pesquisa de Produto"
      />
  
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title fs-5" id="staticBackdropLabel">Adição de Item</h1>
          
        </div>
        
        <div className="modal-body">
          <div className="row">
            <div className="col-4">
              <label htmlFor="inputAddress" className="form-label">CODIGO</label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  value={codProduto}
                  onChange={(e) => setCodProduto(e.target.value)}
                  onBlur={(e) => buscaIten()}
                  className="form-control"
                  id="inputCod"
                  tabIndex="7"
                  required
                />
                <button
                  type="button"
                  id="button-addon2"
                  onClick={(e) => setIsProdutoOpen(true)}
                  className="btn btn-primary"
                >
                  <i className="bi bi-search"></i>
                </button>
              </div>
            </div>
  
            <div className="col-6">
              <label htmlFor="inputAddress" className="form-label">DESCRICAO</label>
              <input
                type="text"
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
                onBlur={(e) => buscaIten()}
                className="form-control"
                id="inputDescricao"
                tabIndex="2"
                required
                autoComplete="off"
              />
            </div>
  
            <div className="col-3">
              <label htmlFor="inputAddress" className="form-label">ESTOQUE ATUAL</label>
              <NumericFormat
                className="form-control"
                id="inputValorTotal"
                value={estoqueAtual}
                tabIndex="9"
                allowLeadingZeros
                decimalSeparator=','
                thousandSeparator='.'
                decimalScale={3}
              />
            </div>
  
            <div className="col-3">
              <label htmlFor="inputAddress" className="form-label">QTD</label>
              <input
                type="text"
                value={qtd}
                onChange={(e) => setQtd(e.target.value)}
                className="form-control"
                id="inputQtd"
                tabIndex="4"
                required
                autoComplete="off"
              />
            </div>
          </div>
        </div>
  
        <div className="modal-footer">
          <button className='btn btn-danger me-3' onClick={props.onRequestClose}>Cancelar</button>
          <button className='btn btn-primary' onClick={AdicionarItem}>Adicionar</button>
        </div>
      </div>
    </Modal>
  
}

export default ModalAdicaoEstoque;