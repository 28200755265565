import customizeScrollbar from "customsb";
import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { vhostV1 } from "../funcoes";
import Navbar from "../navbar/navbar";
import FooterBar from "../footer/footerBar";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link, Navigate } from "react-router-dom";
import './fichaTecnica.css'; 
import SRI_pagination from "../components/pagination/pagination";



function FichaTecnica(){
     const [nPag, setnPag] = useState(1);
     const [totalPaginas, setTotalPaginas] = useState(0);
     const [texto,setTexto] = useState('');
     const [busca,setBusca] = useState("");
     const [produtos,setProdutos] = useState([]);
     const [sResult,setSresult] = useState(0);

     const [dCadastro,setDcadastro] = useState(new Date);
     const [dAlteracao,setDalteracao] = useState(new Date);
     const [dultCompra,setDultcompra] = useState(new Date);
     const [dultVenda,setDultvenda] = useState(new Date);

     const [usaDcadastro,setUsaDcadastro] = useState(false);
     const [usaDAlteracao,setusaDalteracao] = useState(false);
     const [usaDultCompra,setusaDultcompra] = useState(false);
     const [usaDultVenda,setusaDultvenda] = useState(false);

     const [diferenteZero,setDiferenteZero] = useState(false);
     const [estoqueNegativo,setEstoqueNegativo] = useState(false);
     const [vendaZerada,setVendaZerada] = useState(false);


     const [cGrupo,setCgrupo] = useState(0);
     const [cSubGrupo,setCsubGrupo] = useState(0);
     const [cLinha,setClinha] = useState(0);

     const [load,setLoad] = useState(false);
     const [tpBusca,setTpBusca] = useState(0);

     const [erroValidacao, setErrovalidacao] = useState(false);
     const [confirmacao, setConfirmacao] = useState(false);
     const [navegar, setNavegar] = useState(false);
     const [msgErro, setmsgErro] = useState('');

     let config = {
        headers: {
          'Authorization': localStorage.getItem("token")
        }
      }

    function handlePageChange(value){
        if(value === "&laquo;" || value === '... '){
          setnPag(1);
        }else if (value === "&lsaquo;" ){
          if (nPag !== 1){
            setnPag(-1);
          }
        }else if(value === "&rsaquo;"){
          setnPag(+1);        
        }else if(value === "&raquo;" || value === ' ...' ){
          setnPag(totalPaginas);        
        }else{
          setnPag(value);
        }    
       
       }
  
       // Aplicando a personalização com opções diferentes
  // Abaixo é somente um exemplo de personalização, adapte para o seu projeto
  useEffect(() => {
    // O uso do useEffect garante que a função seja chamada apenas no lado do cliente
    customizeScrollbar({
      scrollbarWidth: "10px",
      trackBackground: "#333",
      thumbBackground:
        "linear-gradient(178.8deg, #ffa066 47.41%, #ff9666 65.18%, #ff783a 90.84%)",
      thumbBorderRadius: "10px",
    });
  }, []);

  //buscar 
  useEffect(function(){
    setLoad(true)
    //console.log('numero de Paniga '+nPag);
    const sJson = {}
    sJson.loja=localStorage.getItem('loja'); 
    sJson.empresa=0;
    sJson.grupo=cGrupo;
    sJson.subgrupo=cSubGrupo;
    sJson.linha=cLinha;
    if(usaDcadastro === true)
    { sJson.data_cadastro=dCadastro}
    if(usaDAlteracao === true)
    { sJson.data_alteracao=dAlteracao}
    if(usaDultCompra === true)
    { sJson.data_compra=dultCompra}
    if(usaDultVenda === true)
    { sJson.data_venda=dultVenda}
    sJson.estoque_diferente=diferenteZero
    sJson.estoque_negativo=estoqueNegativo;
    sJson.preco_zerado=vendaZerada;
    sJson.inativo=false;
    sJson.pagina= nPag;
    sJson.regPag=10;
    sJson.busca= busca; 
    sJson.tipoBusca=tpBusca;                   
    
      api.post(vhostV1('webcash/produto/listar'),sJson,config)
        .then(function (response) {
          console.log(response.data.dados);           
          setProdutos(response.data.dados); 
          setSresult(response.data.result)
          setTotalPaginas(response.data.qtdpaginas);
          setLoad(false);
          
                                      
                   
        })
        .catch(function (error) {
          setLoad(false);
          console.log(error);

                  
        });
  
        
      },[busca,nPag])

      return <div>
      <Navbar/>
      <div className="container-fluid titulo offset-lg-1 col-lg-10">
      <h1>Ficha técnica de Produtos</h1>
      <hr />

      <div className="row">
        

        <div className="col-12">
          <div className="input-group mb-3">
            <input onChange={(e) => setTexto(e.target.value)} type="text" className="form-control" placeholder="Digite sua pesquisa" aria-describedby="button-addon2" />
            <button onClick={(e) => setBusca(texto)} className="btn btn-primary" type="button" id="button-addon2">Pesquisar</button>
          </div>
        </div>
      </div>   

       <table id='table-grupos' className="table table-hover table-striped">
          <thead>
          <tr>
              <th scope='col'>Código</th>
              <th scope="col">Descricao</th>
              <th scope="col">Ações</th>
              </tr>
          </thead>
          <tbody className="table-group-divider">
            {  
               produtos.map((produto)=> {
                return <tr key={produto.COD_PRODUTO}>
                    <td>{produto.COD_PRODUTO}</td>
                    <td>{produto.DESCRICAO}</td>
                    <td>
                    <button id='table-prod' className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          Ações
                   </button>
                       <ul className="dropdown-menu">
                          <li>
                             <Link to={`/app/novaFichaTecnica/${produto.ID}/-1`} className="dropdown-item">
                             <i className="bi bi-plus-fill"></i> Adicionar
                             </Link>
                          </li>
                          <li>
                              <Link to={`/app/novoProduto/${produto.ID}/0`} className="dropdown-item">
                              <i className="bi bi-eye-fill"></i> Visualizar
                              </Link>
                         </li>
                          <li>
                               <Link to={`/app/novoProduto/${produto.ID}/2`} className="dropdown-item">
                               <i className="bi bi-pen-fill"></i> Editar
                               </Link>
                         </li>
                         <li>
                                <button  className="dropdown-item">
                                <i className="bi bi-trash-fill"></i> Excluir
                                </button>
                         </li>

                        </ul>
                    </td>
                </tr>
            } )}  
          </tbody>
        </table>

        <div className="row mt-5">
            <SRI_pagination totalPage={totalPaginas} 
                            page={nPag} 
                            limit={10} 
                            siblings={1}
                            onPageChange={handlePageChange}/>
            </div>

        {
         erroValidacao ? 
         <SweetAlert
            danger
            confirmBtnText="OK"
            confirmBtnBsStyle="danger"
            title="Erro"
            onConfirm={() => setErrovalidacao(false)}                            
          >
            {msgErro}
          </SweetAlert>:null
         
        }

        {
         confirmacao ? 
         <SweetAlert
            warning
            showCancel
            showCloseButton
            confirmBtnText="Sim"
            confirmBtnBsStyle="danger"
            cancelBtnText="Não"
            cancelBtnBsStyle='ligth'
            title="Atenção"
           // onConfirm={() =>deleteUser(confirmacaoId)}
            onCancel={() => setConfirmacao(false)}
            focusCancelBtn
            reverseButtons
          >
            Deseja excluir Este Grupo!
          </SweetAlert>:null
         
        }

        {navegar ? <Navigate to='/app/fichaTecnica' /> && window.location.reload()  : null}
   
    </div>  
    <FooterBar/>   
  </div>
  
};

export default FichaTecnica;